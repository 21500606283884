import React from 'react';
import { compose } from 'redux';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { Box, Grid, makeStyles, Typography } from '@material-ui/core';
import tutorflyLogoWhite from 'shared/assets/svg/tutorfly-logo-white.svg';
import facebookLogo from './logo-facebook.svg';
import instagramLogo from './logo-instagram.svg';
import linkedInLogo from './logo-linkedin.svg';
import twitterLogo from './logo-x-twitter.svg';

const currentYear = new Date().getFullYear();

const useStyles = makeStyles(theme => ({
  root: {
    width: '100%',
    color: 'white',
    backgroundColor: '#444',
  },
  BoxContainer: {
    padding: theme.spacing(3),
    maxWidth: '1440px',
    margin: 'auto',
    paddingLeft: '5%',
    paddingRight: '5%',
  },
  LinkListContainer: {
    margin: 'auto',
    maxWidth: '1440px',
    display: 'flex',
    [theme.breakpoints.up('sm')]: {
      flexDirection: 'row',
      justifyContent: 'space-evenly',
    },
    [theme.breakpoints.up('md')]: {
      flexDirection: 'row',
      justifyContent: 'start',
    },
  },
  LinkList: {
    minWidth: 'fit-content',
    whiteSpace: 'nowrap',
    [theme.breakpoints.down('sm')]: {
      textAlign: 'center',
    },
    [theme.breakpoints.up('sm')]: {
      marginRight: '1.5em',
    },
    [theme.breakpoints.up('lg')]: {
      marginRight: '2.5em',
    },
  },
  linkAlign: {
    [theme.breakpoints.down('sm')]: {
      textAlign: 'center',
    },
  },
  sectionContainer: {
    paddingBottom: theme.spacing(4),
  },
  summaryContainer: {
    paddingRight: theme.spacing(3),
    [theme.breakpoints.down('md')]: {
      paddingRight: theme.spacing(0),
    },
  },
  footerLink: {
    fontWeight: 500,
    marginLeft: '0.75em',
    fontSize: '14px',
    textDecoration: 'none',
    color: 'white',
    '&:hover': {
      textDecoration: 'underline',
      cursor: 'pointer',
    },
  },
  footerLinkContainer: {
    paddingBottom: theme.spacing(1),
  },
  whiteLink: {
    fontSize: '13px',
    color: 'white',
    textDecoration: 'none',
    '&:hover': {
      textDecoration: 'underline',
      cursor: 'pointer',
    },
  },
  termsLink: {
    fontSize: '11px',
    color: 'white',
    textDecoration: 'none',
    '&:hover': {
      textDecoration: 'underline',
      cursor: 'pointer',
    },
  },
  termsFooter: {
    marginTop: '2em',
    display: 'flex',
    justifyContent: 'space-between',
    flexDirection: 'row',
  },
  smallWhite: {
    fontSize: '13px',
    color: 'white',
  },
  tinyWhite: {
    fontSize: '11px',
    color: 'white',
  },
  whiteHeader: {
    fontSize: '18px',
    color: 'white',
    marginBottom: '1em',
    marginTop: '1em',
  },
  underlineContainer: {
    borderBottom: '3px solid white',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    [theme.breakpoints.up('sm')]: {
      flexDirection: 'row',
      justifyContent: 'space-between',
      paddingBottom: theme.spacing(3),
    },
  },
  logoContainer: {
    [theme.breakpoints.down('xs')]: {
      marginBottom: theme.spacing(3),
    },
  },
  socialLinksContainer: {
    display: 'flex',
    alignItems: 'end',
    justifyContent: 'end',
    [theme.breakpoints.down('xs')]: {
      marginBottom: theme.spacing(3),
    },
  },
  iconLink: {
    marginBottom: theme.spacing(2),
    alignItems: 'center',
    color: 'white',
    display: 'flex',
    flexDirection: 'row',
    textDecoration: 'none',
    cursor: 'pointer',
  },
}));

const FooterV2 = ({ currentUser }) => {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <Box classes={{ root: classes.BoxContainer }}>
        <Box classes={{ root: classes.underlineContainer }}>
          <Box classes={{ root: classes.logoContainer }}>
            <img
              src={tutorflyLogoWhite}
              height="44"
              maxWidth="100%"
              alt="Tutorfly Logo"
            />
          </Box>
          <Box classes={{ root: classes.socialLinksContainer }}>
            <Typography
              component="a"
              href="https://www.facebook.com/tutorfly.org/"
              variant="body2"
              target="_blank"
              rel="noopener noreferrer"
              className={classes.footerLink}
            >
              <img
                src={facebookLogo}
                height="44"
                maxWidth="100%"
                alt="Facebook Logo"
              />
            </Typography>
            <Typography
              component="a"
              href="https://www.linkedin.com/company/tutorfly"
              variant="body2"
              target="_blank"
              rel="noopener noreferrer"
              className={classes.footerLink}
            >
              <img
                src={linkedInLogo}
                height="44"
                maxWidth="100%"
                alt="LinkedIn Logo"
              />
            </Typography>
            <Typography
              component="a"
              href="https://www.twitter.com/tutorfly_"
              variant="body2"
              target="_blank"
              rel="noopener noreferrer"
              className={classes.footerLink}
            >
              <img
                src={twitterLogo}
                height="44"
                maxWidth="100%"
                alt="X/Twitter Logo"
              />
            </Typography>
            <Typography
              component="a"
              href="https://www.instagram.com/tutorfly"
              variant="body2"
              target="_blank"
              rel="noopener noreferrer"
              className={classes.footerLink}
            >
              <img
                src={instagramLogo}
                height="44"
                maxWidth="100%"
                alt="Instagram Logo"
              />
            </Typography>
          </Box>
        </Box>
        {currentUser ? '' : ''}
        <Grid container classes={{ root: classes.LinkListContainer }}>
          <Grid xs={12} sm={2} item classes={{ root: classes.LinkList }}>
            <Box
              display="flex"
              flexDirection="column"
              pb={2}
              classes={{ root: classes.linkAlign }}
            >
              <Typography variant="h6" className={classes.whiteHeader}>
                About Us
              </Typography>
              <Typography
                component="a"
                href="/about-us/our-story"
                variant="small"
                className={classes.whiteLink}
              >
                Our Story
              </Typography>
              <Typography
                component="a"
                href="/about-us/team"
                variant="small"
                className={classes.whiteLink}
              >
                Meet our Team
              </Typography>
              <Typography
                component="a"
                href="/about-us/media"
                variant="small"
                className={classes.whiteLink}
              >
                In the Media
              </Typography>
            </Box>
          </Grid>
          <Grid xs={12} sm={2} item classes={{ root: classes.LinkList }}>
            <Box
              display="flex"
              flexDirection="column"
              pb={2}
              classes={{ root: classes.linkAlign }}
            >
              <Typography variant="h6" className={classes.whiteHeader}>
                Tutoring for Schools
              </Typography>
              <Typography
                component="a"
                href="/schools"
                variant="small"
                className={classes.whiteLink}
              >
                Our Method
              </Typography>
              <Typography
                component="a"
                href="/schools/#blog"
                variant="small"
                className={classes.whiteLink}
              >
                Tutoring Articles
              </Typography>
              <Typography
                component="a"
                href="/schools/#contact"
                variant="small"
                className={classes.whiteLink}
              >
                Request More Information
              </Typography>
            </Box>
          </Grid>
          <Grid xs={12} sm={2} item classes={{ root: classes.LinkList }}>
            <Box
              display="flex"
              flexDirection="column"
              pb={2}
              classes={{ root: classes.linkAlign }}
            >
              <Typography variant="h6" className={classes.whiteHeader}>
                Tutoring for Families
              </Typography>

              <Typography
                component="a"
                href="/marketplace"
                variant="small"
                className={classes.whiteLink}
              >
                Tutoring Marketplace
              </Typography>
              <Typography
                component="a"
                href="/pricing"
                variant="small"
                className={classes.whiteLink}
              >
                Pricing
              </Typography>
              <Typography
                component="a"
                href="/search"
                variant="small"
                className={classes.whiteLink}
              >
                Find a Tutor
              </Typography>
              <Typography
                component="a"
                href="/request-a-tutor"
                variant="small"
                className={classes.whiteLink}
              >
                Request a Tutor
              </Typography>
              <Typography
                component="a"
                href="/apply"
                variant="small"
                className={classes.whiteLink}
              >
                Become a Tutor
              </Typography>
              <Typography
                component="a"
                href="/faq"
                variant="small"
                className={classes.whiteLink}
              >
                FAQ
              </Typography>
              <Typography
                component="a"
                href="/terms"
                variant="small"
                className={classes.whiteLink}
              >
                Terms of Use
              </Typography>
              <Typography
                component="a"
                href="/support"
                variant="small"
                className={classes.whiteLink}
              >
                Support
              </Typography>
            </Box>
          </Grid>
          <Grid xs={12} sm={2} item classes={{ root: classes.LinkList }}>
            <Box
              display="flex"
              flexDirection="column"
              pb={2}
              classes={{ root: classes.linkAlign }}
            >
              <Typography variant="h6" className={classes.whiteHeader}>
                Contact Us
              </Typography>
              <Typography
                component="a"
                href="mailto:info@tutorfly.org"
                variant="small"
                className={classes.whiteLink}
              >
                info@tutorfly.org
              </Typography>
              <Typography variant="small" className={classes.smallWhite}>
                (424) 653 6159
              </Typography>
              <br />
              <Typography
                component="a"
                href="/contact"
                variant="small"
                className={classes.whiteLink}
              >
                Inquiry Form
              </Typography>
            </Box>
          </Grid>
        </Grid>
        <Box
          alignItems="center"
          justifyContent="space-between"
          flexDirection="row"
          pb={2}
          className={classes.termsFooter}
        >
          <div>
            <Typography
              component="a"
              href="/terms"
              variant="small"
              className={classes.termsLink}
            >
              Terms of Use
            </Typography>
            &nbsp;|&nbsp;
            <Typography
              component="a"
              href="/privacy"
              variant="small"
              className={classes.termsLink}
            >
              Privacy Policy
            </Typography>
            &nbsp;|&nbsp;
            <Typography
              component="a"
              href="/contact"
              variant="small"
              className={classes.termsLink}
            >
              Contact Us
            </Typography>
          </div>
          <Typography variant="small" className={classes.tinyWhite}>
            &copy; {currentYear} All rights reserved
          </Typography>
        </Box>
      </Box>
    </div>
  );
};
FooterV2.propTypes = {
  currentUser: PropTypes.shape.isRequired,
};

const mapStateToProps = state => ({
  currentUser: state.subscriptions.currentUser,
});

export default compose(connect(mapStateToProps))(FooterV2);
