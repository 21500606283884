import React from 'react';
import Button from '@material-ui/core/Button';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(theme => ({
  root: {
    color: theme.palette.error.main,
    backgroundColor: theme.palette.white,
    '&:hover': {
      backgroundColor: theme.palette.grey[200],
    },
    whiteSpace: 'nowrap',
  },
}));

const ErrorBannerButton = props => {
  const classes = useStyles();
  return <Button {...props} className={classes.root} disableElevation />;
};

export default ErrorBannerButton;
