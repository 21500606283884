import { withStyles } from '@material-ui/core/styles/';
import { warningYellow } from 'shared/constants/styleConstants';

const styles = theme => ({
  root: {
    height: '90px',
    [theme.breakpoints.down('lg')]: {
      height: '90px',
    },
    [theme.breakpoints.down('md')]: {
      height: '64px',
    },
    [theme.breakpoints.down('xs')]: {
      height: '56px',
    },
  },
  appBar: {
    backgroundColor: '#FFF',
    boxShadow: '5px 2px 3px rgba(0,0,0,0.07)',
    display: 'flex',
  },
  profileDescriptionContainer: {
    marginLeft: theme.spacing(1),
  },
  headerProfileIcon: {
    marginTop: theme.spacing(0),
  },
  nameLabel: {
    marginBottom: -theme.spacing(0.5),
    lineHeight: 1.15,
  },
  profileDescriptionLabel: {
    marginTop: theme.spacing(0.75),
  },
  profileDropdown: {
    marginLeft: theme.spacing(0.25),
    height: theme.spacing(2),
    width: 'auto',
    color: '#888',
  },
  profileLabelContainer: {
    marginTop: theme.spacing(0.25),
  },
  viewProfileContainer: {
    marginTop: theme.spacing(0.25),
  },
  viewProfileButtonPrimary: {
    marginTop: theme.spacing(2.5),
    marginBottom: theme.spacing(1),
    borderColor: theme.palette.primary.main,
    '&:focus': {
      borderColor: theme.palette.primary.main,
      borderWidth: '2px',
    },
    '&:hover': {
      borderColor: theme.palette.primary.main,
      borderWidth: '2px',
    },
    borderWidth: '2px',
  },
  viewProfileButtonSecondary: {
    marginTop: theme.spacing(2.5),
    marginBottom: theme.spacing(1),
    borderColor: theme.palette.secondary.main,
    '&:focus': {
      borderColor: theme.palette.secondary.main,
      borderWidth: '2px',
    },
    '&:hover': {
      borderColor: theme.palette.secondary.main,
      borderWidth: '2px',
    },
    borderWidth: '2px',
  },
  drawerPaper: {
    width: '100%',
  },
  clearNotifications: {
    textAlign: 'center',
    fontWeight: 700,
    fontSize: '16px',
    [theme.breakpoints.down('xs')]: {
      fontSize: '15px',
    },
  },
  loadingNotifications: {
    width: '100%',
    minWidth: theme.spacing(40),
    textAlign: 'center',
  },
  button: {
    color: '#444',
    fontFamily: 'Raleway',
    height: '100%',
    fontWeight: 500,
    fontSize: '18px',
    whiteSpace: 'nowrap',
    marginLeft: theme.spacing(3),
    marginRight: theme.spacing(3),
    [theme.breakpoints.down('lg')]: {
      fontSize: '17px',
      marginLeft: theme.spacing(2),
      marginRight: theme.spacing(2),
    },
    '@media (max-width: 850px)': {
      fontSize: '16px',
      marginLeft: theme.spacing(1),
      marginRight: theme.spacing(1),
    },
    [theme.breakpoints.down('sm')]: {
      fontSize: '14px',
      marginLeft: theme.spacing(1),
      marginRight: theme.spacing(1),
    },
    marginTop: 'auto',
    marginBottom: 'auto',
    padding: '0',
    borderBottom: '3px solid transparent',
    borderRadius: '2px',
    backgroundColor: 'white',
    '&:hover': {
      backgroundColor: 'white',
      borderBottom: '3px solid #56b0e4',
      borderRadius: '2px',
    },
    '&:visited': {
      textDecoration: 'none',
    },
    '&:focus': {
      textDecoration: 'none',
      backgroundColor: 'white',
    },
    '&:active': {
      textDecoration: 'none',
      backgroundColor: 'white',
      borderBottom: '3px solid #56b0e4',
    },
  },
  buttonActive: {
    borderBottom: '3px solid #56b0e4',
  },
  applyButton: {
    boxShadow: 'none',
    color: '#FFF',
    fontFamily: 'Raleway',
    fontWeight: 'heavy',
  },
  loginButton: {
    borderRadius: '45px',
    fontSize: '18px',
    [theme.breakpoints.down('sm')]: {
      fontSize: '14px',
    },
    color: '#56b0e4',
    boxShadow: '0px 0px 10px rgba(3,3,3,0.1)',
    border: '3px solid #56b0e4',
    justifyContent: 'right',
    padding: '0 20px',
    margin: 'auto',
    '&:hover': {
      backgroundColor: '#56b0e4',
      color: 'white',
    },
  },
  signupButton: {
    whiteSpace: 'nowrap',
    borderRadius: '45px',
    fontSize: '18px',
    [theme.breakpoints.down('sm')]: {
      fontSize: '14px',
    },
    boxShadow: '0px 0px 10px rgba(3,3,3,0.1)',
    border: '3px solid #56b0e4',
    justifyContent: 'right',
    padding: '0 20px',
    margin: 'auto',
    color: 'white',
    backgroundColor: '#56b0e4',
    '&:hover': {
      backgroundColor: 'white',
      color: '#56b0e4',
    },
    '@media (max-width: 1100px)': {
      display: 'none',
    },
  },
  notificationList: {
    maxHeight: '500px',
    transformOrigin: 'center bottom',
  },
  profileDropdownMenu: {
    transformOrigin: 'center bottom',
  },
  logo: {
    height: '90px',
    width: 'auto',
    [theme.breakpoints.down('md')]: {
      height: '40px',
    },
  },
  menuIcon: {
    height: theme.spacing(6),
    width: 'auto',
  },
  noAuthLogo: {
    height: '90px',
    width: 'auto',
    [theme.breakpoints.down('md')]: {
      height: '55px',
    },
    [theme.breakpoints.down('sm')]: {
      height: '40px',
    },
  },
  referralLogo: {
    height: theme.spacing(6),
    width: 'auto',
    paddingLeft: theme.spacing(5),
    [theme.breakpoints.down('md')]: {
      height: theme.spacing(5),
    },
  },
  desktopIcon: {
    height: theme.spacing(4),
    color: '#888',
    width: 'auto',
  },
  muiIconBell: {
    marginBottom: theme.spacing(0.5),
    color: '#888',
  },
  muiIconActive: {
    marginBottom: theme.spacing(0.5),
    color: theme.palette.primary.main,
  },
  muiIcon: {
    marginBottom: theme.spacing(0.5),
    color: '#888',
  },
  headerIconContainer: {
    marginTop: theme.spacing(0.5),
  },
  iconLabel: {
    fontSize: '14px',
    color: '#888',
    fontFamily: 'Raleway',
  },
  iconLabelActive: {
    fontSize: '14px',
    color: theme.palette.primary.main,
    fontFamily: 'Raleway',
  },
  switchChipStyle: {
    marginTop: theme.spacing(2),
    borderWidth: '2px',
    borderColor: '#888',
    fontFamily: 'Raleway',
  },
  editButtonStyle: {
    borderWidth: '2px',
  },
  warnOldBrowser: {
    flex: 1,
    height: 'auto',
    backgroundColor: warningYellow,
    padding: theme.spacing(2, 0, 2, 0),
  },
  headerContainer: {
    width: '1440px',
    maxWidth: '1440px',
    margin: 'auto',
    display: 'none',
    '@media (min-width: 756px)': {
      display: 'block',
    },
  },
  warningContentContainer: {
    maxWidth: '1000px',
    margin: '0px auto',
  },
  warningHeader: {
    color: '#333',
  },
  warningIcon: {
    height: theme.spacing(6),
    width: 'auto',
    marginRight: theme.spacing(1.5),
  },
  footerLink: {
    fontSize: '16px',
    fontFamily: 'Raleway',
    fontWeight: 500,
  },
  footerText: {
    fontFamily: 'Raleway',
    fontWeight: 500,
  },
  warningButton: {
    backgroundColor: '#111',
    color: warningYellow,
    '&:hover': {
      backgroundColor: '#333',
    },
  },
  logoMobile: {
    flex: 1,
    width: '200px',
    textAlign: 'center',
  },
  profileListItemText: {
    marginLeft: theme.spacing(1),
    fontWeight: 700,
    fontSize: '16px',
    [theme.breakpoints.down('xs')]: {
      fontSize: '15px',
    },
  },
  notificationHeaderText: {
    fontWeight: 500,
    fontFamily: 'Raleway',
    letterSpacing: 'normal',
    lineHeight: '1',
    fontSize: '16px',
    textTransform: 'none !important',
    [theme.breakpoints.down('xs')]: {
      fontSize: '15px',
    },
  },
  muiListIcon: {
    minWidth: theme.spacing(3),
  },
  clearText: {
    textAlign: 'center',
  },
  rightSpace: {
    width: '40px',
    maxHeight: '10px',
  },
  menuButton: {
    marginLeft: '0px',
    marginRight: '10px',
    color: 'white',
  },
  toolbar: {
    flexDirection: 'column',
  },
  grow: {
    flexGrow: 1,
  },
  blueNavPosition: {
    position: 'absolute',
    zIndex: '120',
    left: '0',
    width: '100%',
    display: 'block',
    [theme.breakpoints.down('md')]: {
      top: '64px',
    },
  },
  sectionDesktop: {
    display: 'none',
    width: '100%',
    flexGrow: 1,
    '@media (min-width: 756px)': {
      display: 'flex',
    },
  },
  logoLink: {
    textDecoration: 'none',
  },
  tutorflyTitleText: {
    marginLeft: theme.spacing(1),
    textDecoration: 'none',
    [theme.breakpoints.down('xs')]: {
      fontSize: '1.5rem',
    },
  },
  sectionMobile: {
    display: 'none',
    width: '100%',
    flexGrow: 1,
    '@media (max-width: 755px)': {
      display: 'block',
    },
  },
  profileMenuItem: {
    '&:hover': {
      backgroundColor: 'transparent',
    },
  },
});

export default withStyles(styles);
