import React from 'react';
import PropTypes from 'prop-types';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import PrimaryButtonV4 from '../../../shared/components/Buttons/PrimaryButtonV4';
import withStyles from './TutoringHeroStyles';
import image1 from './card-image-1.png';
import image2 from './card-image-2.png';

const TutoringHero = ({ classes }) => (
  <React.Fragment>
    <Typography variant="h4" className={classes.sectionHeader}>
      Tutoring for Families
    </Typography>
    <Grid container>
      <Grid item xs={12} md={6}>
        <div className={classes.cardContainer}>
          <div
            className={classes.imageContainer}
            style={{ backgroundImage: `url(${image1})` }}
          />
          <div className={classes.textContainer}>
            <Typography variant="h4" className={classes.title}>
              Find a Tutor
            </Typography>
            <div>
              <p style={{ fontWeight: 'bold' }}>
                Empowering students for success since 2017
              </p>
              <p>
                Connect to our 2,000-strong network of high-caliber university
                and high school students who are passionate about sharing their
                subject knowledge with younger learners.
              </p>
            </div>
            <div style={{ marginRight: 'auto' }}>
              <PrimaryButtonV4 color="orange" href="/search">
                Get started
              </PrimaryButtonV4>
            </div>
          </div>
        </div>
      </Grid>
      <Grid item xs={12} md={6}>
        <div className={classes.cardContainer}>
          <div
            className={classes.imageContainer}
            style={{ backgroundImage: `url(${image2})` }}
          />
          <div className={classes.textContainer}>
            <Typography variant="h4" className={classes.title}>
              Become a Tutor
            </Typography>
            <div>
              <p style={{ fontWeight: 'bold' }}>
                Tutorfly is ideal for high-achieving high school and university
                students who are passionate about helping younger learners and
                are looking for a flexible income stream.
              </p>
              <p>
                Start your application and we&apos;ll be in touch with the next
                steps.
              </p>
            </div>
            <div style={{ marginRight: 'auto' }}>
              <PrimaryButtonV4 color="orange" href="/apply">
                Apply now
              </PrimaryButtonV4>
            </div>
          </div>
        </div>
      </Grid>
    </Grid>
  </React.Fragment>
);

TutoringHero.propTypes = {
  classes: PropTypes.object.isRequired, // eslint-disable-line
};

export default withStyles(TutoringHero);
