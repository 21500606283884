import React from 'react';
import Layout from 'shared/components/Layout/Layout';
import LandingScene from 'marketplace/components/LandingScene';

const marketplaceLanding = () => ({
  chunks: ['home'],
  title: 'Tutorfly',
  description:
    'Affordable K-12 and university peer tutoring. Anytime. Anywhere.',
  component: (
    <Layout>
      <LandingScene />
    </Layout>
  ),
});

async function action({ store }) {
  const { currentUser } = store.getState().subscriptions;

  if (currentUser) {
    return { redirect: '/sessions' };
  }

  return marketplaceLanding();
}

export default action;
