import React from 'react';
import PropTypes from 'prop-types';
import Student from 'shared/assets/images/student-02.png';
import withStyles from './LandingSceneStyles';
import Services from '../Services/Services';
import LandingHero from '../LandingHero/LandingHero';
import FeatureHero from '../FeatureHero/FeatureHero';
import TutoringHero from '../TutoringHero/TutoringHero';
import TestimonialHero from '../TestimonialHero/TestimonialHero';
import NetworkPartners from '../NetworkPartners/NetworkPartners';

const LandingScene = ({ classes }) => (
  <div className={classes.landingScene}>
    <LandingHero />
    <div className={classes.heroSubContent}>
      <div className={classes.innerSubContent}>
        <Services />
      </div>
    </div>
    <FeatureHero
      subtitle="Tutoring for Schools"
      title="Helping students realize their potential"
      image={Student}
      buttonText="Learn more"
      link="/schools"
      buttonColor="blue"
    >
      <p>
        At Tutorfly, we provide personalized online and in-person tutoring
        services to support students in reaching their academic goals. Our
        experienced tutors are dedicated to helping every student unlock their
        full potential. We offer accessible and engaging tutoring sessions, with
        user-friendly tools that seamlessly integrate with your SSO platform.
      </p>
      <p>
        We strive to bridge the opportunity gap by offering in-school and
        after-school tutoring support. Our approach includes implementing
        individualized small group sessions that support students and teachers
        in the classroom, both in-person and online.
      </p>
      <p>
        Our commitment is to ensure that every student receives the tailored
        academic support they need to succeed.
      </p>
    </FeatureHero>
    <br />
    <div
      className={classes.heroSubContent}
      style={{ marginBottom: '-10em', position: 'relative', zIndex: '1' }}
    >
      <TutoringHero />
    </div>
    <br />
    <div>
      <TestimonialHero />
    </div>
    <br />
    <div>
      <NetworkPartners />
    </div>
  </div>
);

LandingScene.propTypes = {
  classes: PropTypes.object.isRequired, // eslint-disable-line
};

export default withStyles(LandingScene);
