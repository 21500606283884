import React from 'react';
import PropTypes from 'prop-types';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import FeatureLink from '../FeatureLink/FeatureLink';
import IconOne from './services-icon-1.svg';
import IconTwo from './services-icon-2.svg';
import IconThree from './services-icon-3.svg';
import withStyles from './ServicesStyles';

const WhyTutorfly = ({ classes }) => (
  <React.Fragment>
    <Typography variant="h4" className={classes.sectionHeader}>
      Our Services
    </Typography>

    <Grid container>
      <Grid xs={12} md={4} item>
        <FeatureLink
          title="High-Impact Tutoring for Schools"
          icon={IconOne}
          description="Tutorfly is committed to closing the achievement gap by making high-impact tutoring accessible to all students. Students work in small groups with a consistent tutor. Sessions run a minimum of three times per week and are embedded into the school day or immediately before or after school."
          link="/schools"
        />
      </Grid>
      <Grid xs={12} md={4} item>
        <FeatureLink
          title="On-Demand Tutoring for Schools"
          icon={IconTwo}
          description="Tutorfly provides personalized online and in-person tutoring services to support students in achieving their academic goals. Our experienced and dedicated tutors are committed to helping every student reach their full potential."
          link="/schools"
        />
      </Grid>
      <Grid xs={12} md={4} item>
        <FeatureLink
          title="1:1 Tutoring Online"
          icon={IconThree}
          description="Tutorfly's one-to-one tutoring tailors the learning experience to each student's skill level and learning style. This approach fosters a deeper understanding of the subject matter and boosts students' confidence leading to improved academic performance and overall success."
          link="/marketplace"
        />
      </Grid>
    </Grid>
  </React.Fragment>
);

WhyTutorfly.propTypes = {
  classes: PropTypes.shape.isRequired,
};

export default withStyles(WhyTutorfly);
