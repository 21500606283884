import { withStyles } from '@material-ui/core/styles/';

const styles = theme => ({
  notificationHeaderText: {
    fontWeight: 700,
  },
  secondary: {
    marginTop: theme.spacing(-1),
    paddingBottom: theme.spacing(1),
  },
});

export default withStyles(styles);
