import withStyles from '@material-ui/core/styles/withStyles';

const styles = theme => ({
  blueNav: {
    // position: 'sticky',
    top: '90px',
    [theme.breakpoints.down('md')]: {
      top: '0',
    },
    '@media (max-width: 756px)': {
      display: 'none',
    },
    left: '0',
    width: '100%',
    zIndex: '100',
    backgroundColor: '#56b0e4',
    boxShadow: '5px 2px 3px rgba(0,0,0,0.07)',
  },
  container: {
    margin: '0 auto',
    width: '100%',
    maxWidth: '1440px',
  },
  button: {
    color: 'white',
    fontFamily: 'Raleway',
    height: '48px',
    fontSize: '16px',
    whiteSpace: 'nowrap',

    marginLeft: theme.spacing(2),
    marginRight: theme.spacing(2),
    [theme.breakpoints.down('lg')]: {
      fontSize: '16px',
    },
    [theme.breakpoints.down('sm')]: {
      fontSize: '14px',
      marginLeft: theme.spacing(1),
      marginRight: theme.spacing(1),
    },
    '&:hover': {
      backgroundColor: 'transparent',
      '&::before': {
        content: '""  ',
        position: 'absolute',
        top: '0',
        left: '50%',
        transform: 'translateX(-50%)',
        border: 'solid transparent',
        borderWidth: '8px 8px 0 8px',
        borderColor: 'white transparent transparent transparent',
      },
    },
    '&:visited, &:focus, &:active, &:hover': {
      textDecoration: 'none',
    },
  },
  buttonActive: {
    '&::before': {
      content: '""  ',
      position: 'absolute',
      top: '0',
      left: '50%',
      transform: 'translateX(-50%)',
      border: 'solid transparent',
      borderWidth: '8px 8px 0 8px',
      borderColor: 'white transparent transparent transparent',
    },
  },
});

export default withStyles(styles);
