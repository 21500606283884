import React from 'react';
import PropTypes from 'prop-types';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import LogoAce from './logo-ace.svg';
import LogoAlabamaState from './logo-alabama-state.svg';
import LogoKeep from './logo-keep.svg';
import LogoTea from './logo-tea.svg';
import LogoKip from './logo-kipmcgrath.svg';
import withStyles from './NetworkPartnersStyles';

const NetworkPartners = ({ classes }) => (
  <React.Fragment>
    <Typography variant="h4" className={classes.sectionHeader}>
      Our network
    </Typography>

    <Grid container className={classes.container}>
      <Grid xs={12} md={6} item className={classes.columnContainer}>
        <div>
          <Typography variant="h4" className={classes.columnHeader}>
            Verified by
          </Typography>
          <p>
            Texas Education Agency and the Alabama State Department of
            Education, the Ohio Department of Education and the Kansas Office of
            Recovery.
          </p>
        </div>
        <div className={classes.logoContainer}>
          <img
            src={LogoAce}
            className={classes.logoImage}
            alt="Ohio Afterscool Child Enrichment logo"
          />
          <img
            src={LogoAlabamaState}
            className={classes.logoImage}
            alt="Alabama State logo"
          />
          <img
            src={LogoKeep}
            className={classes.logoImage}
            alt="Kansas KEP logo"
          />
          <img
            src={LogoTea}
            className={classes.logoImage}
            alt="Texas Education Agency logo"
          />
        </div>
      </Grid>
      <Grid
        xs={12}
        md={6}
        item
        className={`${classes.columnContainer} ${classes.lastColumnContainer}`}
      >
        <div>
          <Typography variant="h4" className={classes.columnHeader}>
            Partners
          </Typography>
          <p>Powered by leading learning technology platforms. </p>
        </div>
        <div className={classes.logoContainer}>
          <img src={LogoKip} className={classes.logoImage} alt="" />
        </div>
      </Grid>
    </Grid>
  </React.Fragment>
);

NetworkPartners.propTypes = {
  classes: PropTypes.shape.isRequired,
};

export default withStyles(NetworkPartners);
