import React from 'react';
import PropTypes from 'prop-types';
import ListItemText from '@material-ui/core/ListItemText';
import MenuItem from '@material-ui/core/MenuItem';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';

import { Mutation } from 'react-apollo';
import { connect } from 'react-redux';
import { compose } from 'redux';
import gql from 'graphql-tag';
import { removeNotification } from 'shared/actions/subscriptions';
import { errorAlert } from 'shared/utilities/alerts';
import { navigateToPathName } from 'shared/utilities/navigation';
import withStyles from './NotificationItemStyles';

export const READ_NOTIFICATION_MUTATION = gql`
  mutation readNotification($notificationId: ID!) {
    readNotification(notificationId: $notificationId)
  }
`;

/* eslint-disable react/prefer-stateless-function */

class NotificationItem extends React.Component {
  static propTypes = {
    classes: PropTypes.object.isRequired, // eslint-disable-line
    notification: PropTypes.object.isRequired, // eslint-disable-line
    removeNotification: PropTypes.func.isRequired,
  };

  handleClick = readNotification => e => {
    e.preventDefault();
    const { notification } = this.props;
    readNotification({
      variables: {
        notificationId: notification.id,
      },
    });
    if (notification.link && notification.link.includes('tutorfly.org')) {
      const notificationUrlParts = notification.link.split('tutorfly.org');
      const urlEndpoint = notificationUrlParts[1];
      navigateToPathName(urlEndpoint);
    } else if (
      notification.link &&
      notification.link.includes('tutorfly.com')
    ) {
      const notificationUrlParts = notification.link.split('tutorfly.com');
      const urlEndpoint = notificationUrlParts[1];
      navigateToPathName(urlEndpoint);
    } else {
      navigateToPathName(notification.link);
    }
  };

  handleError = error => {
    errorAlert('Error occurred.', error);
  };

  handleComplete = () => {
    const {
      notification,
      removeNotification: reduxRemoveNotification,
    } = this.props;
    reduxRemoveNotification(notification.id);
  };

  render() {
    const { handleClick, handleError, handleComplete } = this;
    const { notification } = this.props;
    return (
      <Mutation
        mutation={READ_NOTIFICATION_MUTATION}
        onError={handleError}
        onCompleted={handleComplete}
      >
        {(readNotification, { loading }) => {
          if (loading) return null;
          return (
            <MenuItem onClick={handleClick(readNotification)} button>
              <ListItemText
                primary={
                  <React.Fragment>
                    <Typography variant="subtitle1" noWrap>
                      <b>{notification.type}</b>
                    </Typography>
                  </React.Fragment>
                }
                secondary={
                  <React.Fragment>
                    <Grid container wrap="nowrap" spacing={2}>
                      <Grid item xs>
                        <Typography variant="body1" noWrap>
                          {notification.details}
                        </Typography>
                      </Grid>
                    </Grid>
                  </React.Fragment>
                }
              />
            </MenuItem>
          );
        }}
      </Mutation>
    );
  }
}

export default compose(
  connect(null, {
    removeNotification,
  }),
  withStyles,
)(NotificationItem);

/* eslint-disable react/prefer-stateless-function */
