import withStyles from '@material-ui/core/styles/withStyles';
import * as StyleConstants from 'shared/constants/styleConstants';

const styles = theme => ({
  container: {
    padding: theme.spacing(3),
    color: 'white',
  },
  iconContainer: {
    backgroundColor: StyleConstants.primaryColorDark,
    borderRadius: '100%',
    margin: 'auto',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    marginBottom: theme.spacing(5),
    height: '150px',
    width: '150px',
    [theme.breakpoints.only('md')]: {
      height: '125px',
      width: '125px',
    },
  },
  textContainer: {
    display: 'flex',
    justifyContent: 'center',
    width: '100%',
    paddingTop: '5em',
    paddingBottom: '3em',
    [theme.breakpoints.up('md')]: {
      paddingTop: '10em',
      paddingBottom: '11em',
      margin: 'auto',
      justifyContent: 'space-around',
      paddingRight: '3em',
    },
    [theme.breakpoints.down('sm')]: {
      paddingBottom: '0',
      textAlign: 'center',
    },
  },
  title: {
    color: 'white',
    padding: '1.5rem 0',
    fontSize: '3.25rem',
    [theme.breakpoints.down('sm')]: {
      textAlign: 'center',
      margin: 'auto',
      fontSize: '42px',
      maxWidth: '650px',
    },
    [theme.breakpoints.down('xs')]: {
      fontSize: '32px',
    },
  },
  subtitle: {
    color: 'white',
    [theme.breakpoints.down('sm')]: {
      textAlign: 'center',
      margin: 'auto',
      maxWidth: '650px',
      fontSize: '32px',
    },
    [theme.breakpoints.down('xs')]: {
      fontSize: '24px',
    },
  },
  icon: {
    margin: 'auto',
    height: '55%',
    width: '55%',
  },
  description: {
    color: 'white',
    whiteSpace: 'pre-line',
  },
  heroContent: {
    position: 'relative',
    overflow: 'hidden',
    backgroundColor: StyleConstants.primaryColorDark,
    margin: '0px auto',
    paddingTop: '3em',
    width: '100%',
  },
  topWave: {
    display: 'flex',
    justifyContent: 'center',
    position: 'absolute',
    left: '0',
    top: '-3px',
    pointerEvents: 'none',
    minWidth: '100vw',
    width: '100%',
    height: '185px',

    [theme.breakpoints.down('sm')]: {
      width: '768px',
      height: '115px',
    },
  },
  bottomWave: {
    display: 'flex',
    justifyContent: 'center',
    position: 'absolute',
    left: '0',
    bottom: '-1px',
    minWidth: '100vw',
    width: '100%',
    height: '265px',
    pointerEvents: 'none',
    [theme.breakpoints.down('sm')]: {
      width: '768px',
      height: '115px',
    },
    [theme.breakpoints.down('xs')]: {
      left: '-30%',
    },
    transform: 'scaleX(-1)', // Flip image
  },
  heroSubContent: {
    paddingTop: theme.spacing(2),
    margin: '1em auto',
    width: '100%',
    maxWidth: '1440px',
  },
  innerContent: {
    maxWidth: '1440px',
    margin: 'auto',
    flex: 1,
    paddingLeft: '5%',
    paddingRight: '5%',
  },
  innerSubContent: {
    maxWidth: '1440px',
    margin: 'auto',
    flex: 1,
    paddingLeft: '5%',
    paddingRight: '5%',
    marginTop: '-10em',
    zIndex: '10',
    position: 'relative',
    [theme.breakpoints.down('sm')]: {
      marginTop: '-5em',
    },
  },
  image: {
    [theme.breakpoints.up('md')]: {
      width: 'auto',
      height: '740px',
      marginTop: '3.25em',
      marginLeft: 'auto',
    },
    [theme.breakpoints.down('sm')]: {
      height: '480px',
      margin: 'auto',
      marginTop: '0',
      marginBottom: '-1em',
    },
  },
});

export default withStyles(styles);
