import React from 'react';
import PropTypes from 'prop-types';
import Divider from '@material-ui/core/Divider';
import Grid from '@material-ui/core/Grid';
import student from 'shared/assets/images/student-01.png';
import WaveSvg from './WaveSvg';
import withStyles from './LandingHeroStyles';
import LandingText from '../LandingText/LandingText';

const LandingHero = ({ classes }) => (
  <div className={classes.heroContent}>
    <Grid item className={classes.wave}>
      <WaveSvg className={classes.test} />
    </Grid>
    <div className={classes.innerContent}>
      <Grid container>
        <Grid item container xs={12} md={6} lg={6}>
          <LandingText />
        </Grid>
        <Grid
          item
          container
          xs={12}
          md={6}
          lg={6}
          alignItems="center"
          justify="center"
        >
          <img
            className={classes.image}
            src={student}
            alt="A Tutorfly Student"
          />
        </Grid>
      </Grid>
      <Divider />
    </div>
  </div>
);

LandingHero.propTypes = {
    classes: PropTypes.object.isRequired, // eslint-disable-line
};

export default withStyles(LandingHero);
