import swal from 'sweetalert';
import { navigateToPathName } from 'shared/utilities/navigation';

export const successAlert = (title, text = '', button = false) => {
  if (text === '') {
    swal({
      title: `${title}`,
      icon: 'success',
      button,
    });
  } else {
    swal({
      title: `${title}`,
      text: `${text}`,
      icon: 'success',
      button,
    });
  }
};

export const successAlertWithButton = (title, text = '', button, link) => {
  swal({
    title: `${title}`,
    text: `${text}`,
    icon: 'success',
    buttons: {
      accept: {
        text: button,
      },
    },
  }).then(value => {
    switch (value) {
      case 'accept':
        navigateToPathName(link);
        break;

      default:
    }
  });
};

export const warnAlert = (title, text = '', button = false) => {
  if (text === '') {
    swal({
      title: `${title}`,
      icon: 'warning',
      button,
    });
  } else {
    swal({
      title: `${title}`,
      text: `${text}`,
      icon: 'warning',
      button,
    });
  }
};

export const warnAlertWithButton = (title, text = '', button, link) => {
  swal({
    title: `${title}`,
    text: `${text}`,
    icon: 'warning',
    buttons: {
      accept: {
        text: button,
      },
    },
  }).then(value => {
    switch (value) {
      case 'accept':
        navigateToPathName(link);
        break;

      default:
    }
  });
};

export const errorAlert = (title, text = '', button = false) => {
  if (text === '') {
    swal({
      title: `${title}`,
      icon: 'error',
      button,
    });
  } else {
    swal({
      title: `${title}`,
      text: `${text}`,
      icon: 'error',
      button,
    });
  }
};

export const infoAlert = (title, text = '', button = false) => {
  if (text === '') {
    swal({
      title: `${title}`,
      icon: 'info',
      button,
    });
  } else {
    swal({
      title: `${title}`,
      text: `${text}`,
      icon: 'info',
      button,
    });
  }
};

// no payment method warning
export const noPaymentMethodWarning = link => {
  warnAlertWithButton(
    'Missing Payment Info!',
    'Please go to account settings and update your payment information.',
    'Account Settings',
    link,
  );
};

// verify sms warning
export const verifySMSWarning = link => {
  warnAlertWithButton(
    'Verify Phone Number',
    'Please go to account settings and verify your phone number before booking a session.',
    'Account Settings',
    link,
  );
};
