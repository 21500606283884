import React from 'react';
import PropTypes from 'prop-types';
import { Link, Button } from '@material-ui/core';
import withStyles from './BlueNavStyles';

const BlueNav = ({ classes, activeLink, menuType }) => {
  let links = [];

  if (menuType === 'aboutUs') {
    links = [
      { id: '1', href: '/about-us/our-story', text: 'Our Story' },
      { id: '2', href: '/about-us/team', text: 'Meet our Team' },
      { id: '3', href: '/about-us/media', text: 'In the Media' },
    ];
  } else if (menuType === 'forSchools') {
    links = [
      { id: '1', href: '/schools/#method', text: 'Our Method' },
      { id: '2', href: '/schools/#blog', text: 'Tutoring Articles' },
      { id: '3', href: '/schools/#contact', text: 'Request more Information' },
    ];
  } else if (menuType === 'forFamilies') {
    links = [
      { id: '1', href: '/marketplace', text: 'Tutoring Marketplace' },
      { id: '3', href: '/search', text: 'Find a Tutor' },
      { id: '5', href: '/apply', text: 'Become a Tutor' },
      { id: '2', href: '/pricing', text: 'Pricing' },
      { id: '7', href: '/faq', text: 'FAQ' },
      { id: '6', href: '/support', text: 'Support' },
    ];
  }

  return (
    <div className={classes.blueNav}>
      <div className={classes.container}>
        {links.map(link => (
          <Button
            key={link.id}
            href={link.href}
            component={Link}
            color="inherit"
            className={`${classes.button} ${
              activeLink === link.id ? classes.buttonActive : ''
            }`}
          >
            {link.text}
          </Button>
        ))}
      </div>
    </div>
  );
};

BlueNav.propTypes = {
  classes: PropTypes.object.isRequired, // eslint-disable-line
  activeLink: PropTypes.string,
  menuType: PropTypes.string.isRequired,
};

BlueNav.defaultProps = {
  activeLink: '',
};

export default withStyles(BlueNav);
