import withStyles from '@material-ui/core/styles/withStyles';

const styles = theme => ({
  sectionHeader: {
    textAlign: 'center',
    marginBottom: theme.spacing(4),
  },
  cardContainer: {
    background: 'white',
    borderRadius: '8px',
    boxShadow: '3px 3px 8px -5px rgba(0 ,0 ,0, 0.5)',
    margin: '2em',
    overflow: 'hidden',
    height: '90%',
  },
  imageContainer: {
    backgroundSize: 'cover',
    backgroundPosition: 'center',
    height: '295px',
    borderRadius: '8px',
  },
  textContainer: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    padding: '2em',
    [theme.breakpoints.up('md')]: {
      height: '280px',
    },
  },
});

export default withStyles(styles);
