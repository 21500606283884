/* eslint-disable jsx-a11y/accessible-emoji */
/* eslint-disable react/no-unescaped-entities */
import React from 'react';
import {
  withStyles,
  MuiThemeProvider,
  createMuiTheme,
} from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import Link from '@material-ui/core/Link';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import MuiDialogContent from '@material-ui/core/DialogContent';
import MuiDialogActions from '@material-ui/core/DialogActions';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import Typography from '@material-ui/core/Typography';
import logo from 'shared/assets/TutorflyLogoBB.png';

const styles = theme => ({
  root: {
    margin: 0,
    padding: theme.spacing(2),
  },
  closeButton: {
    position: 'absolute',
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  },
  title: {
    fontFamily: 'Raleway',
    fontWeight: 'bold',
  },
  detailText: {
    fontWeight: 300,
    fontSize: '16px',
    fontFamily: 'Raleway',
    marginTop: '1px',
  },
});

const outerTheme = createMuiTheme({
  typography: {
    fontFamily: 'Raleway',
  },
});

const DialogTitle = withStyles(styles)(props => {
  const { children, classes, onClose } = props;
  return (
    <MuiThemeProvider theme={outerTheme}>
      <MuiDialogTitle disableTypography className={classes.root}>
        <Typography variant="h6">{children}</Typography>
        {onClose ? (
          <IconButton
            aria-label="close"
            className={classes.closeButton}
            onClick={onClose}
          >
            <CloseIcon />
          </IconButton>
        ) : null}
      </MuiDialogTitle>
    </MuiThemeProvider>
  );
});

const DialogContent = withStyles(theme => ({
  root: {
    padding: theme.spacing(2),
  },
}))(MuiDialogContent);

const DialogActions = withStyles(theme => ({
  root: {
    margin: 0,
    padding: theme.spacing(1),
  },
}))(MuiDialogActions);

class BrowserSupportCheck extends React.Component {
  constructor(props) {
    super(props);

    const showWarningBanner = global.isInvalidBrowser;
    if (showWarningBanner) {
      this.state = {
        open: true,
      };
    } else {
      this.state = {
        open: false,
      };
    }
  }

  handleClose = () => {
    this.setState({ open: false });
  };

  render() {
    return (
      <div>
        <Dialog
          onClose={this.handleClose}
          aria-labelledby="customized-dialog-title"
          open={this.state.open}
        >
          <DialogTitle id="customized-dialog-title" onClose={this.handleClose}>
            {`Please Upgrade Safari to the Latest Version`}
          </DialogTitle>
          <DialogContent dividers>
            <Typography gutterBottom>
              You are currently using an outdated version of Safari that is no
              longer compatible with Tutorfly’s website. Please{' '}
              <Link
                href="https://support.apple.com/en-us/HT204416"
                target="_blank"
                rel="noopener"
              >
                click here
              </Link>{' '}
              and follow the instructions to download the latest version of{' '}
              Safari.
            </Typography>
            <Typography gutterBottom>
              Alternatively, you can{' '}
              <Link
                href="https://www.google.com/chrome/"
                target="_blank"
                rel="noopener"
              >
                click here
              </Link>{' '}
              to download Google Chrome, to use in place of Safari. If you
              prefer to access Tutorfly from your phone, you can always download
              our mobile app from the{' '}
              <Link
                href="https://apps.apple.com/app/tutorfly/id1458933486"
                target="_blank"
                rel="noopener"
              >
                iOS App Store
              </Link>{' '}
              or{' '}
              <Link
                href="https://play.google.com/store/apps/details?id=com.tutorfly.tutorfly"
                target="_blank"
                rel="noopener"
              >
                Google Play Store
              </Link>!
            </Typography>
          </DialogContent>
          <DialogContent dividers>
            <span>
              <Typography gutterBottom>The Tutorfly Team</Typography>
              <img
                src={logo}
                alt="Tutor"
                height="45"
                width="45"
                alignContents="center"
              />
            </span>
          </DialogContent>

          <DialogActions>
            <Button onClick={this.handleClose} color="primary">
              OK
            </Button>
          </DialogActions>
        </Dialog>
      </div>
    );
  }
}

export default BrowserSupportCheck;
