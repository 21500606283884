import React from 'react';
import { compose } from 'redux';
import PropTypes from 'prop-types';
import Button from '@material-ui/core/Button';
import withWidth from '@material-ui/core/withWidth';
import { withStyles } from '@material-ui/core/styles';
import { isEmpty } from 'shared/utilities/utils';

const styles = theme => ({
  root: {
    boxShadow: 'none',
    flexWrap: 'nowrap',
    alignItems: 'center',
    color: '#fff',
    cursor: 'pointer',
    minWidth: theme.spacing(6),
    textTransform: 'none',
    '&:focus': {
      backgroundColor: theme.palette.primary.main,
      outline: 0,
      borderWidth: '2px',
    },
    '&:hover': {
      backgroundColor: theme.palette.primary.main,
      borderWidth: '2px',
    },
    borderWidth: '2px',
  },
});

const PrimaryButtonV3 = props => {
  let size;
  if (isEmpty(props.size)) {
    size = props.width === 'xs' || props.width === 'sm' ? 'small' : 'medium';
  } else {
    size = props.size;
  }
  return (
    <Button
      disableElevation
      variant="contained"
      color="primary"
      {...props}
      size={size}
    >
      {props.children}
    </Button>
  );
};

PrimaryButtonV3.propTypes = {
  children: PropTypes.node.isRequired,
  classes: PropTypes.object.isRequired, // eslint-disable-line
  width: PropTypes.string.isRequired,
  size: PropTypes.string,
};

PrimaryButtonV3.defaultProps = {
  size: '',
};

export default compose(withStyles(styles), withWidth())(PrimaryButtonV3);
