import { withStyles } from '@material-ui/core/styles';

const styles = theme => ({
  divider: {
    margin: theme.spacing(4, 0),
  },
  landingScene: {
    overflow: 'visible',
    marginTop: '-5px',
  },
  paper: {
    margin: '47em 0 5em 0',
    padding: theme.spacing(3),
  },
  heroSubContent: {
    paddingTop: theme.spacing(2),
    margin: '1em auto',
    width: '100%',
    maxWidth: '1440px',
  },
  innerContent: {
    maxWidth: '1440px',
    margin: 'auto',
    flex: 1,
    paddingLeft: '5%',
    paddingRight: '5%',
  },
  innerSubContent: {
    maxWidth: '1440px',
    margin: 'auto',
    flex: 1,
    paddingLeft: '5%',
    paddingRight: '5%',
    marginTop: '-10em',
    zIndex: '10',
    position: 'relative',
    [theme.breakpoints.down('sm')]: {
      marginTop: '-5em',
    },
  },
});

export default withStyles(styles);
