import React from 'react';
import { ApolloConsumer } from 'react-apollo';

// higher order component which passes down client
export default function withClient(WrappedComponent) {
  const hoc = props => (
    <ApolloConsumer>
      {client => <WrappedComponent client={client} {...props} />}
    </ApolloConsumer>
  );
  return hoc;
}
