import withStyles from '@material-ui/core/styles/withStyles';

const styles = theme => ({
  variableAvatar: {
    height: '300px',
    width: '300px',
    fontSize: '110px',
    [theme.breakpoints.down('lg')]: {
      height: '250px',
      width: '250px',
      fontSize: '110px',
    },
    [theme.breakpoints.down('md')]: {
      height: '180px',
      width: '180px',
      fontSize: '95px',
    },
    [theme.breakpoints.down('sm')]: {
      height: '120px',
      width: '120px',
      fontSize: '45px',
    },
    [theme.breakpoints.down('xs')]: {
      height: '60px',
      width: '60px',
      fontSize: '24px',
    },
  },
  variableAvatarV2: {
    height: '120px',
    width: '120px',
    fontSize: '45px',
    [theme.breakpoints.down('sm')]: {
      height: '100px',
      width: '100px',
      fontSize: '40px',
    },
    [theme.breakpoints.down('xs')]: {
      height: '80px',
      width: '80px',
      fontSize: '35px',
    },
  },
  variableMessageProfileAvatar: {
    [theme.breakpoints.up('md')]: {
      height: '170px',
      width: '170px',
      fontSize: '70px',
    },
    [theme.breakpoints.down('sm')]: {
      height: '120px',
      width: '120px',
      fontSize: '45px',
    },
    [theme.breakpoints.down('xs')]: {
      height: '100px',
      width: '100px',
      fontSize: '40px',
    },
  },
  sessionCardVariableAvatar: {
    height: '120px',
    width: '120px',
    fontSize: '45px',
    [theme.breakpoints.down('sm')]: {
      height: '80px',
      width: '80px',
      fontSize: '35px',
    },
    [theme.breakpoints.down('xs')]: {
      height: '50px',
      width: '50px',
      fontSize: '24px',
    },
  },
  sessionCardVariableV2: {
    height: '60px',
    width: '60px',
    fontSize: '24px',
    [theme.breakpoints.down('sm')]: {
      height: '50px',
      width: '50px',
      fontSize: '20px',
    },
  },
  tutorRequestAvatar: {
    height: '90px',
    width: '90px',
    fontSize: '42px',
    [theme.breakpoints.down('sm')]: {
      height: '80px',
      width: '80px',
      fontSize: '35px',
    },
    [theme.breakpoints.down('xs')]: {
      height: '50px',
      width: '50px',
      fontSize: '24px',
    },
  },
  editProfileAvatar: {
    height: '120px',
    width: '120px',
    fontSize: '45px',
    [theme.breakpoints.down('sm')]: {
      height: '75px',
      width: '75px',
      fontSize: '32px',
    },
    [theme.breakpoints.down('xs')]: {
      height: '70px',
      width: '70px',
      fontSize: '28px',
    },
  },
  headerBarAvatar: {
    marginTop: theme.spacing(0.5),
    height: '30px',
    width: '30px',
    fontSize: '16px',
  },
  avatar: {},
  expandableAvatar: {
    height: '100%',
    width: '100%',
  },
  messageMobileHeaderAvatar: {
    height: '45px',
    width: '45px',
    fontSize: '22px',
  },
  extraSmallAvatar: {
    height: '60px',
    width: '60px',
    fontSize: '24px',
  },
  smallAvatar: {
    height: '120px',
    width: '120px',
    fontSize: '45px',
  },
  mediumAvatar: {
    height: '180px',
    width: '180px',
    fontSize: '95px',
  },
  largeAvatar: {
    height: '250px',
    width: '250px',
    fontSize: '110px',
  },
  referralAvatar: {
    height: '135px',
    width: '135px',
    fontSize: '55px',
    [theme.breakpoints.down('sm')]: {
      height: '110px',
      width: '110px',
      fontSize: '40px',
    },
  },
  centered: {
    margin: 'auto',
  },
  squareAvatarWrapper: {
    boxShadow: '3px 3px 10px 1px rgba(0 ,0 ,0, 0.5)',
    height: '64%',
    left: '6%',
    position: 'absolute',
    width: '40%',
    alignContent: 'center',
  },
  squareAvatarImage: {
    height: '100%',
    width: '100%',
    objectFit: 'cover',
  },
  squareAvatarInitials: {
    fontSize: '92px',
    fontWeight: 'bold',
    color: 'white',
    position: 'absolute',
    top: '15%',
    left: '50%',
    transform: 'translateX(-50%) translateY(-50%)',
    '@media (max-width: 830px)': {
      // eslint-disable-line no-useless-computed-key
      fontSize: '36px',
    },
    [theme.breakpoints.down('sm')]: {
      top: '20%',
    },
  },
});

export default withStyles(styles);
