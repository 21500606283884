import withStyles from '@material-ui/core/styles/withStyles';
import * as StyleConstants from 'shared/constants/styleConstants';
import butterfly from 'shared/assets/svg/butterfly-blue.svg';

const styles = theme => ({
  container: {
    padding: theme.spacing(3),
  },
  iconContainer: {
    backgroundColor: StyleConstants.primaryColor,
    borderRadius: '100%',
    margin: 'auto',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    marginBottom: theme.spacing(5),
    height: '150px',
    width: '150px',
    [theme.breakpoints.only('md')]: {
      height: '125px',
      width: '125px',
    },
  },
  titleContainer: {
    display: 'flex',
    justifyContent: 'center',
    width: '100%',
    [theme.breakpoints.up('md')]: {
      paddingTop: '3em',
      height: '250px',
    },
  },
  title: {
    color: 'white',
    padding: '1em 0',
    [theme.breakpoints.down('sm')]: {
      textAlign: 'center',
      margin: 'auto',
      fontSize: '38px',
      maxWidth: '650px',
    },
    [theme.breakpoints.down('xs')]: {
      fontSize: '24px',
    },
  },
  icon: {
    margin: 'auto',
    height: '55%',
    width: '55%',
  },
  description: {
    display: 'none',
    whiteSpace: 'pre-line',
    [theme.breakpoints.up('md')]: {
      display: 'block',
    },
  },
  heroContent: {
    position: 'relative',
    overflow: 'hidden',
    backgroundImage: `url("${butterfly}")`,
    backgroundSize: '75vw',
    backgroundPosition: '-7em 2em',
    [theme.breakpoints.up('md')]: {
      backgroundSize: '50vw',
      backgroundPosition: '0 0',
    },
    backgroundRepeat: 'no-repeat',
    backgroundColor: StyleConstants.primaryColor,
    margin: '0px auto',
    width: '100%',
  },
  wave: {
    display: 'flex',
    justifyContent: 'center',
    position: 'absolute',
    left: '50%',
    transform: 'translateX(-50%)',
    bottom: '0',
    minWidth: '100vw',
    width: '1920px',
    height: '265px',
    [theme.breakpoints.down('sm')]: {
      width: '768px',
      height: '115px',
    },
  },
  topWave: {
    display: 'flex',
    justifyContent: 'center',
    position: 'absolute',
    left: '50%',
    transform: 'translateX(-50%)',
    top: '-3px',
    minWidth: '100vw',
    width: '100%',
    height: '185px',
    [theme.breakpoints.down('sm')]: {
      width: '768px',
      height: '115px',
    },
  },
  bottomWaveDesktop: {
    [theme.breakpoints.down('xs')]: {
      display: 'none',
    },
  },
  bottomWaveMobile: {
    display: 'none',
    [theme.breakpoints.down('xs')]: {
      display: 'block',
      width: '768px',
      marginLeft: '-50%',
    },
  },
  heroSubContent: {
    paddingTop: theme.spacing(2),
    margin: '1em auto',
    width: '100%',
    maxWidth: '1440px',
  },
  innerContent: {
    maxWidth: '1440px',
    margin: 'auto',
    marginTop: '11em',
    flex: 1,
    paddingLeft: '5%',
    paddingRight: '5%',
  },
  innerSubContent: {
    maxWidth: '1440px',
    margin: 'auto',
    flex: 1,
    paddingLeft: '5%',
    paddingRight: '5%',
    marginTop: '-10em',
    zIndex: '10',
    position: 'relative',
    [theme.breakpoints.down('sm')]: {
      marginTop: '-5em',
    },
  },
  image: {
    width: '212px',
    height: '212px',
    margin: '15rem auto 5rem',
    display: 'block',
  },
  textContainer: {
    textAlign: 'center',
    color: 'white',
    fontSize: '32px',
    fontWeight: '500',
    [theme.breakpoints.up('lg')]: {
      padding: '0 2em',
    },
  },
  captionContainer: {
    lineHeight: '31px',
    color: 'white',
    fontSize: '24px',
    textAlign: 'center',
    margin: '1.5em 0',
  },
});

export default withStyles(styles);
