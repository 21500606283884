import React from 'react';
import PropTypes from 'prop-types';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import TopWave from 'shared/assets/svg/rounded-wave-bottom-inverse.svg';
import BottomWave from 'shared/assets/svg/landing-wave-bottom.svg';
import withStyles from './FeatureHeroStyles';
import PrimaryButtonV4 from '../../../shared/components/Buttons/PrimaryButtonV4';

const FeatureHero = ({
  title,
  subtitle,
  children,
  image,
  link,
  buttonText,
  buttonColor,
  classes,
}) => (
  <div className={classes.heroContent}>
    <Grid item className={classes.topWave}>
      <img src={TopWave} width="100%" alt="" />
    </Grid>
    <div className={classes.innerContent}>
      <Grid container>
        <Grid item container xs={12} md={6} lg={5}>
          <Grid
            container
            justify="center"
            alignItems={{
              xs: 'start',
              lg: 'center',
            }}
            direction="column"
            wrap="nowrap"
            className={classes.textContainer}
            item
            xs={12}
          >
            <Grid item>
              <Typography
                variant="h4"
                className={classes.subtitle}
                style={{ display: subtitle === '' ? 'none' : 'block' }}
              >
                {subtitle}
              </Typography>
            </Grid>
            <Grid item>
              <Typography
                variant="h2"
                className={classes.title}
                style={{ display: title === '' ? 'none' : 'block' }}
              >
                {title}
              </Typography>
            </Grid>
            <Grid item style={{ marginBottom: '1rem', color: 'white' }}>
              <p className={classes.description}>{children}</p>
            </Grid>
            <Grid item>
              {' '}
              {link ? (
                <PrimaryButtonV4 color={buttonColor} href={link}>
                  {buttonText}
                </PrimaryButtonV4>
              ) : (
                ''
              )}
            </Grid>
          </Grid>
        </Grid>
        <Grid
          item
          container
          xs={12}
          md={6}
          lg={7}
          alignItems="center"
          justify="center"
        >
          <img className={classes.image} src={image} alt="A Tutorfly Student" />
        </Grid>
      </Grid>

      <Grid item className={classes.bottomWave}>
        <img src={BottomWave} width="100%" alt="" />
      </Grid>
    </div>
  </div>
);

FeatureHero.propTypes = {
  title: PropTypes.string,
  subtitle: PropTypes.string,
  children: PropTypes.node,
  image: PropTypes.string.isRequired,
  link: PropTypes.string,
  buttonText: PropTypes.string,
  buttonColor: PropTypes.string,
  classes: PropTypes.object.isRequired, // eslint-disable-line
};

FeatureHero.defaultProps = {
  title: '',
  subtitle: '',
  children: '',
  link: '',
  buttonText: '',
  buttonColor: '',
};

export default withStyles(FeatureHero);
