/*
  A module for utility functions related to the initials as profile picture feature
*/
import { initialsBackgroundColors } from 'shared/constants/styleConstants';

/**
 * Makes sure we fetch the char code for letter at index i
 * making sure that the index exists.
 */
const getCharCodeForIndex = (name, i) => {
  const charCode = name.toUpperCase().charCodeAt(i);
  return Number.isNaN(charCode) ? 0 : charCode;
};

const getAverageCharCode = (firstName, lastName) => {
  if (!firstName || !lastName) {
    return 0;
  }

  if (firstName.length === 0 || lastName.length === 0) {
    return 0;
  }

  const totalCharWeightReducer = (acc, currVal) => acc + currVal;

  const nameWeights = [
    getCharCodeForIndex(firstName, 0),
    getCharCodeForIndex(firstName, -1),
    getCharCodeForIndex(lastName, 0),
    getCharCodeForIndex(lastName, -1),
  ];

  const totalCharWeight = nameWeights.reduce(totalCharWeightReducer);
  return totalCharWeight;
};

/* Map a first & last name to the background color used in their initials profile avatar */
export const mapNameToColor = (firstName, lastName) => {
  const avgCharCode = getAverageCharCode(firstName, lastName);
  const mod = initialsBackgroundColors.length;
  const index = Math.floor(avgCharCode % mod);
  return initialsBackgroundColors[index] || 'black';
};

export const getInitials = (firstName, lastName) => {
  let firstInitial = '';
  let lastInitial = '';

  if (firstName) {
    firstInitial = firstName.charAt(0).toUpperCase();
  }

  if (lastName) {
    lastInitial = lastName.charAt(0).toUpperCase();
  }

  return firstInitial + lastInitial;
};

export const getInitial = name => name.charAt(0).toUpperCase();
