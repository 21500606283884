import React from 'react';
import PropTypes from 'prop-types';
import Button from '@material-ui/core/Button';
import { withStyles } from '@material-ui/core/styles';

const styles = theme => ({
  root: {
    boxShadow: 'none',
    fontWeight: '400',
    flexWrap: 'nowrap',
    alignItems: 'center',
    cursor: 'pointer',
    minWidth: theme.spacing(6),
    color: 'white',
    textTransform: 'uppercase',
    borderRadius: '1.5rem',
    '&:focus': {
      backgroundColor: theme.palette.primary.main,
      outline: 0,
      borderWidth: '2px',
    },
    borderWidth: '2px',
  },
  blue: {
    backgroundColor: theme.palette.primary.main,
    '&:hover': {
      backgroundColor: theme.palette.primary.main,
    },
  },
  orange: {
    backgroundColor: theme.palette.secondary.main,
    '&:hover': {
      backgroundColor: theme.palette.secondary.main,
    },
  },
});

const PrimaryButtonV4 = props => {
  const { color, classes, ...restProps } = props;

  return (
    <Button
      disableElevation
      variant="contained"
      className={`${classes.root} ${classes[color]}`}
      {...restProps}
    >
      {props.children}
    </Button>
  );
};

PrimaryButtonV4.propTypes = {
  children: PropTypes.node.isRequired,
  classes: PropTypes.object.isRequired, // eslint-disable-line
  color: PropTypes.oneOf(['blue', 'orange']),
};

PrimaryButtonV4.defaultProps = {
  color: 'blue',
};

export default withStyles(styles)(PrimaryButtonV4);
