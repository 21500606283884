import withStyles from '@material-ui/core/styles/withStyles';

const styles = theme => ({
  container: {
    display: 'flex',
    justifyContent: 'center',
    width: '100%',
    [theme.breakpoints.up('md')]: {
      height: '550px',
    },
  },
  ctaButton: {
    paddingTop: theme.spacing(5),
  },
  title: {
    color: 'white',
    padding: '1em 0',
    [theme.breakpoints.down('sm')]: {
      textAlign: 'center',
      margin: 'auto',
      fontSize: '38px',
      maxWidth: '650px',
    },
    [theme.breakpoints.down('xs')]: {
      fontSize: '24px',
    },
  },
});

export default withStyles(styles);
