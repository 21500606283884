import withStyles from '@material-ui/core/styles/withStyles';

const styles = theme => ({
  root: {
    padding: theme.spacing(6),
    marginTop: theme.spacing(16),
    display: 'block',
  },
  container: {
    maxWidth: '1440px',
    margin: 'auto',
  },
  sectionHeader: {
    textAlign: 'center',
    marginBottom: theme.spacing(6),
    marginTop: theme.spacing(6),
    [theme.breakpoints.down('sm')]: {
      marginBottom: theme.spacing(3),
    },
  },
  columnHeader: {
    fontSize: '24px',
  },
  columnContainer: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    [theme.breakpoints.up('md')]: {
      padding: theme.spacing(6),
      marginBottom: theme.spacing(6),
    },
    [theme.breakpoints.down('sm')]: {
      margin: '1em 2em',
      textAlign: 'center',
    },
  },
  lastColumnContainer: {
    [theme.breakpoints.up('md')]: {
      borderLeft: '1px solid grey',
    },
    [theme.breakpoints.down('sm')]: {
      paddingTop: theme.spacing(6),
      borderTop: '1px solid grey',
    },
  },
  logoContainer: {
    display: 'flex',
    flexWrap: 'wrap',
    width: '100%',
    [theme.breakpoints.down('sm')]: {
      justifyContent: 'center',
    },
  },
  logoImage: {
    boxShadow: '0px 2px 10px rgba(3,3,3,0.1)',
    display: 'inline-block',
    height: '70px',
    margin: '0.5rem',
  },
});

export default withStyles(styles);
