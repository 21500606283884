import React from 'react';
import PropTypes from 'prop-types';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { useSnackbar } from 'notistack';

import Paper from '@material-ui/core/Paper';
import Box from '@material-ui/core/Box';
import Collapse from '@material-ui/core/Collapse';
import Hidden from '@material-ui/core/Hidden';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import ErrorOutlineIcon from '@material-ui/icons/ErrorOutline';

import SessionErrorDialog from 'marketplace/components/dialog/SessionErrorDialog';
import ErrorBannerButton from 'shared/components/Buttons/ErrorBannerButton';
import withClient from 'shared/components/ApolloClient/withClient';
import { GET_PAYMENT_ERROR_SESSIONS_FOR_USER_V2 } from 'shared/constants/gql-constants';

const useStyles = makeStyles(theme => ({
  errorText: {
    color: theme.palette.white,
    [theme.breakpoints.down('sm')]: {
      lineHeight: 1.0,
    },
  },
  errorIcon: {
    color: theme.palette.white,
  },
  paper: {
    backgroundColor: theme.palette.error.main,
  },
}));

function SessionErrorBanner({ client, currentUser }) {
  const classes = useStyles();
  const { enqueueSnackbar } = useSnackbar();
  const [paymentErrorSessions, setPaymentErrorSessions] = React.useState([]);
  const [sessionErrorDialogOpen, setSessionErrorDialogOpen] = React.useState(
    false,
  );
  const paymentBannerText =
    'Your credit card was declined. Please click here to ensure your tutor is paid.';

  const getPaymentErrorSessions = async () => {
    if (currentUser) {
      try {
        const { data } = await client.query({
          query: GET_PAYMENT_ERROR_SESSIONS_FOR_USER_V2,
          fetchPolicy: 'network-only',
        });

        setPaymentErrorSessions(data.getPaymentErrorSessionsForUserV2);
      } catch (err) {
        setPaymentErrorSessions([]);
      }
    }
  };

  const removeSession = sessionId => {
    const remainingSessions = paymentErrorSessions.filter(
      session => session.id !== sessionId,
    );
    setPaymentErrorSessions(remainingSessions);
    enqueueSnackbar(`Thank you! We successfully charged this session!`, {
      variant: 'success',
    });
  };

  const shouldShowPaymentError = paymentErrorSessions.length > 0;

  // On initial load of banner get all the user's error sessions
  React.useEffect(() => {
    getPaymentErrorSessions();
  }, []);

  // Check if there are no more error sessions. Close the dialog if there aren't any
  React.useEffect(
    () => {
      if (paymentErrorSessions.length === 0) {
        setSessionErrorDialogOpen(false);
      }
    },
    [paymentErrorSessions],
  );

  /**
   * Session error banner showing up for larger screens (>= SM)
   */
  const sessionErrorBannerLarge = (
    <Box display="flex" alignItems="center">
      <Box p={1} pl={3}>
        <ErrorOutlineIcon className={classes.errorIcon} />
      </Box>
      <Box p={1} flexGrow={1}>
        <Typography variant="subtitle1" className={classes.errorText}>
          {paymentBannerText}
        </Typography>
      </Box>
      <Box p={1} pr={3}>
        <ErrorBannerButton onClick={() => setSessionErrorDialogOpen(true)}>
          Fix sessions
        </ErrorBannerButton>
      </Box>
    </Box>
  );

  /**
   * Session error banner showing up for smaller screens (size XS)
   */
  const sessionErrorBannerSmall = (
    <Box display="flex" alignItems="center">
      <Box p={1}>
        <ErrorOutlineIcon className={classes.errorIcon} />
      </Box>
      <Box p={1} flexGrow={1}>
        <Typography variant="subtitle1" className={classes.errorText}>
          {paymentBannerText}
        </Typography>
      </Box>
      <Box p={1}>
        <ErrorBannerButton
          onClick={() => setSessionErrorDialogOpen(true)}
          size="small"
        >
          Fix
        </ErrorBannerButton>
      </Box>
    </Box>
  );

  return (
    <Paper elevation={0} className={classes.paper} square>
      <Collapse in={shouldShowPaymentError}>
        <Hidden smUp>{sessionErrorBannerSmall}</Hidden>
        <Hidden xsDown>{sessionErrorBannerLarge}</Hidden>
      </Collapse>

      <SessionErrorDialog
        open={sessionErrorDialogOpen}
        onClose={() => setSessionErrorDialogOpen(false)}
        sessions={paymentErrorSessions}
        currentUser={currentUser}
        removeSession={removeSession}
      />
    </Paper>
  );
}

SessionErrorBanner.propTypes = {
  client: PropTypes.func.isRequired,
  currentUser: PropTypes.shape.isRequired,
};

const mapStateToProps = state => ({
  currentUser: state.subscriptions.currentUser,
});

export default compose(withClient, connect(mapStateToProps))(
  SessionErrorBanner,
);
