import withStyles from '@material-ui/core/styles/withStyles';

const styles = theme => ({
  root: {
    flexDirection: 'column',
    display: 'flex',
    margin: 'auto 0',
    height: '100%',
  },
  frontPageTutor: {
    margin: 'auto 0',
    width: '100%',
  },
  sectionHeader: {
    textAlign: 'center',
    marginBottom: theme.spacing(6),
    [theme.breakpoints.down('sm')]: {
      marginBottom: theme.spacing(3),
    },
  },
  list: {
    marginTop: '-2em',
    marginBottom: '-2em',
  },
  bullet: {
    alignI: '-2em',
  },
  text: {
    fontSize: '20px',
    paddingRight: theme.spacing(2),
    marginBottom: theme.spacing(5),
    [theme.breakpoints.down('md')]: {
      marginBottom: theme.spacing(4),
      fontSize: '17px',
    },
    [theme.breakpoints.down('sm')]: {
      marginBottom: theme.spacing(3),
      fontSize: '15px',
    },
    [theme.breakpoints.down('xs')]: {
      marginBottom: theme.spacing(3),
      fontSize: '13px',
    },
  },
  bulletContainer: {
    marginBottom: theme.spacing(0),
    [theme.breakpoints.down('sm')]: {
      marginBottom: theme.spacing(3),
    },
  },
  bold: {
    fontWeight: 'bold',
  },
});

export default withStyles(styles);
