import { withStyles } from '@material-ui/core/styles';
import butterfly from 'shared/assets/svg/butterfly-blue.svg';

const styles = theme => ({
  divider: {
    margin: theme.spacing(4, 0),
  },
  landingScene: {
    overflow: 'visible',
  },
  paper: {
    margin: '47em 0 5em 0',
    padding: theme.spacing(3),
  },
  heroContent: {
    position: 'relative',
    overflow: 'hidden',
    backgroundImage: `url("${butterfly}")`,
    backgroundSize: '150vw',
    backgroundPosition: '50% -1em',
    [theme.breakpoints.up('md')]: {
      backgroundSize: '100em',
      backgroundPosition: '50% -25em',
    },
    backgroundRepeat: 'no-repeat',
    backgroundColor: '#4CA7E0',
    margin: '0px auto',
    width: '100%',
  },
  wave: {
    display: 'flex',
    justifyContent: 'center',
    position: 'absolute',
    left: '50%',
    transform: 'translateX(-50%)',
    bottom: '0',
    minWidth: '100vw',
    width: '1920px',
    height: '265px',
    [theme.breakpoints.down('sm')]: {
      width: '768px',
      height: '115px',
    },
  },
  heroSubContent: {
    paddingTop: theme.spacing(2),
    margin: '1em auto',
    width: '100%',
    maxWidth: '1440px',
  },
  innerContent: {
    maxWidth: '1440px',
    margin: 'auto',
    flex: 1,
    paddingLeft: '5%',
    paddingRight: '5%',
  },
  innerSubContent: {
    maxWidth: '1440px',
    margin: 'auto',
    flex: 1,
    paddingLeft: '5%',
    paddingRight: '5%',
    marginTop: '-10em',
    zIndex: '10',
    position: 'relative',
    [theme.breakpoints.down('sm')]: {
      marginTop: '-5em',
    },
  },
  image: {
    [theme.breakpoints.up('md')]: {
      width: 'auto',
      height: '740px',
      marginTop: '3.25em',
      marginLeft: 'auto',
    },
    [theme.breakpoints.down('sm')]: {
      height: '480px',
      margin: 'auto',
      marginTop: '0',
      marginBottom: '-1em',
    },
  },
  test: {
    backgroundColor: 'transparent',
  },
});

export default withStyles(styles);
