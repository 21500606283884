import React from 'react';

const SVG = () => (
  <svg
    preserveAspectRatio="none"
    viewBox="0 0 1920 264"
    width="100%"
    height="100%"
    version="1.1"
    id="Layer_1"
    xmlns="http://www.w3.org/2000/svg"
    x="0px"
    y="0px"
  >
    <path
      fill="#ffffff"
      d="M0,80.2c0,0,321.6-128.1,834.6-60.6S1375.2,240.4,1920,265H0V80.2z"
    />
  </svg>
);

export default SVG;
