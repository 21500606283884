import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { addNotification } from 'shared/actions/subscriptions';
import { USER_NOTIFICATIONS_QUERY } from 'shared/constants/gql-constants';

class SetupNotifications extends React.Component {
  static propTypes = {
    addNotification: PropTypes.func.isRequired,
    client: PropTypes.shape.isRequired,
    children: PropTypes.node.isRequired,
    currentUser: PropTypes.shape.isRequired,
  };

  componentDidMount() {
    const {
      client,
      currentUser,
      addNotification: addNotificationRedux,
    } = this.props;
    if (currentUser) {
      client
        .query({ query: USER_NOTIFICATIONS_QUERY })
        .then(data => {
          data.data.getNotificationsQuery.forEach(notification => {
            addNotificationRedux(notification);
          });
        })
        .catch();
    }
  }

  componentWillUnmount() {
    // Unsubscribe to the notifications subscription when the notification icon
    // on the header dismounts.
    if (this.querySubscription) {
      this.querySubscription.unsubscribe();
    }
  }

  render() {
    return this.props.children;
  }
}

const mapStateToProps = state => ({
  currentUser: state.subscriptions.currentUser,
});

export default connect(mapStateToProps, {
  addNotification,
})(SetupNotifications);
