import React, { Fragment } from 'react';
import Grid from '@material-ui/core/Grid';
import ListItem from '@material-ui/core/ListItem';
import List from '@material-ui/core/List';
import Divider from '@material-ui/core/Divider';
import Badge from '@material-ui/core/Badge';
import Chip from '@material-ui/core/Chip';
import LensRoundedIcon from '@material-ui/icons/LensRounded';
import BuildRoundedIcon from '@material-ui/icons/BuildRounded';
import SwapCallsIcon from '@material-ui/icons/SwapCalls';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import Typography from '@material-ui/core/Typography';
import SearchIcon from '@material-ui/icons/Search';
import TutorIcon from '@material-ui/icons/Contacts';
import SchoolIcon from '@material-ui/icons/School';
import SignUpIcon from '@material-ui/icons/PersonAdd';
import Referral from '@material-ui/icons/RedeemRounded';
import SessionsIcon from '@material-ui/icons/LibraryBooksRounded';
import InboxIcon from '@material-ui/icons/ChatBubbleRounded';
import CastForEducationIcon from '@material-ui/icons/CastForEducation';
import AccountSettings from '@material-ui/icons/SettingsApplicationsRounded';
import Request from '@material-ui/icons/SupervisedUserCircleRounded';
import Login from '@material-ui/icons/Person';
import ExitToAppIcon from '@material-ui/icons/ExitToAppRounded';
import SvgIcon from '@material-ui/core/SvgIcon';
import ProfileAvatar from 'shared/components/ProfileAvatar/ProfileAvatar';
import {
  AVATAR_STYLE_XS,
  SHOW_REQUEST_A_TUTOR_FORM,
} from 'shared/constants/index';
import Link from 'shared/components/Link/Link';

const PricingIcon = (
  <SvgIcon>
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      viewBox="0 0 24 24"
    >
      <path
        fill="#757575"
        d="M7,15H9C9,16.08 10.37,17 12,17C13.63,17 15,16.08 15,15C15,13.9 13.96,13.5 11.76,12.97C9.64,12.44 7,11.78 7,9C7,7.21 8.47,5.69 10.5,5.18V3H13.5V5.18C15.53,5.69 17,7.21 17,9H15C15,7.92 13.63,7 12,7C10.37,7 9,7.92 9,9C9,10.1 10.04,10.5 12.24,11.03C14.36,11.56 17,12.22 17,15C17,16.79 15.53,18.31 13.5,18.82V21H10.5V18.82C8.47,18.31 7,16.79 7,15Z"
      />
    </svg>
  </SvgIcon>
);

const loggedOutUserItems = classes => (
  <div>
    <ListItem button component={Link} to="/request-a-tutor">
      <ListItemIcon>
        <Request />
      </ListItemIcon>
      <ListItemText
        primary={
          <Typography
            variant="overline"
            className={classes.notificationHeaderText}
          >
            <span>Request a Tutor</span>
          </Typography>
        }
      />
    </ListItem>
    <ListItem button component={Link} to="/search">
      <ListItemIcon>
        <SearchIcon />
      </ListItemIcon>
      <ListItemText
        primary={
          <Typography
            variant="overline"
            className={classes.notificationHeaderText}
          >
            <span>Search Tutors</span>
          </Typography>
        }
      />
    </ListItem>
    <ListItem button component={Link} to="/apply">
      <ListItemIcon>
        <TutorIcon />
      </ListItemIcon>
      <ListItemText
        primary={
          <Typography
            variant="overline"
            className={classes.notificationHeaderText}
          >
            <span>Apply to Tutor</span>
          </Typography>
        }
      />
    </ListItem>
    <ListItem button component={Link} to="/schools">
      <ListItemIcon>
        <SchoolIcon />
      </ListItemIcon>
      <ListItemText
        primary={
          <Typography
            variant="overline"
            className={classes.notificationHeaderText}
          >
            <span>Tutoring for Schools</span>
          </Typography>
        }
      />
    </ListItem>
    <ListItem button component={Link} to="/marketplace">
      <ListItemIcon>{PricingIcon}</ListItemIcon>
      <ListItemText
        primary={
          <Typography
            variant="overline"
            className={classes.notificationHeaderText}
          >
            <span>Tutoring for Families</span>
          </Typography>
        }
      />
    </ListItem>
  </div>
);

const loginSignupItems = classes => (
  <div>
    <ListItem button component={Link} to="/login">
      <ListItemIcon>
        <Login />
      </ListItemIcon>
      <ListItemText
        primary={
          <Typography
            variant="overline"
            className={classes.notificationHeaderText}
          >
            <span>Login</span>
          </Typography>
        }
      />
    </ListItem>
    <ListItem button component={Link} to="/signup">
      <ListItemIcon>
        <SignUpIcon />
      </ListItemIcon>
      <ListItemText
        primary={
          <Typography
            variant="overline"
            className={classes.notificationHeaderText}
          >
            <span>Sign up</span>
          </Typography>
        }
      />
    </ListItem>
  </div>
);

export const loggedOutDrawerOptions = (classes, handleDrawerToggle) => (
  <div>
    <div className={classes.toolbar} />
    <Divider />
    <List onClick={handleDrawerToggle}>{loggedOutUserItems(classes)}</List>
    <Divider />
    <List onClick={handleDrawerToggle}>{loginSignupItems(classes)}</List>
  </div>
);

export const loggedInDrawerOptions = (
  currentUser,
  classes,
  handleDrawerToggle,
  logout,
  showMessageBadge,
  messageBadgeAmount,
  isTutorContext,
  profileLabel,
  isDualProfile,
  handleContextClick,
  editProfile,
  isStudentProfile,
  isAdmin,
) => (
  <div>
    {isTutorContext && (
      <div>
        <List onClick={handleDrawerToggle}>
          <div>
            <ListItem disableRipple onClick={editProfile}>
              <ListItemText
                primary={
                  <Fragment>
                    <Grid
                      container
                      direction="row"
                      justify="flex-start"
                      alignItems="center"
                      wrap="nowrap"
                    >
                      <Grid item>
                        <ProfileAvatar
                          firstName={
                            currentUser ? currentUser.profile.firstName : ''
                          }
                          lastName={
                            currentUser ? currentUser.profile.lastName : ''
                          }
                          src={
                            currentUser
                              ? currentUser.profile.profilePicture.src
                              : ''
                          }
                          size={AVATAR_STYLE_XS}
                        />
                      </Grid>
                      <Grid
                        className={classes.profileDescriptionContainer}
                        item
                        xs
                        container
                        direction="column"
                        justify="center"
                        alignItems="flex-start"
                        wrap="nowrap"
                      >
                        <Typography
                          className={classes.nameLabel}
                          variant="subtitle1"
                        >{`${currentUser.profile.firstName} ${
                          currentUser.profile.lastName
                        }`}</Typography>
                        <Typography
                          variant="body2"
                          className={classes.profileDescriptionLabel}
                        >
                          {`${profileLabel} on Tutorfly`}
                        </Typography>
                      </Grid>
                    </Grid>
                    <div className={classes.viewProfileContainer}>
                      {isDualProfile && (
                        <Grid
                          container
                          direction="row"
                          justify="center"
                          alignItems="center"
                        >
                          <Chip
                            icon={<SwapCallsIcon />}
                            label={`Switch to ${
                              isTutorContext ? 'client' : 'tutor'
                            } mode`}
                            onClick={handleContextClick}
                            variant="outlined"
                            className={classes.switchChipStyle}
                          />
                        </Grid>
                      )}
                    </div>
                  </Fragment>
                }
              />
            </ListItem>
          </div>
        </List>
        <Divider />
        <List onClick={handleDrawerToggle}>
          <div>
            <ListItem button component={Link} to="/inbox">
              <ListItemIcon>
                <Badge
                  invisible={!showMessageBadge}
                  color="secondary"
                  badgeContent={messageBadgeAmount}
                >
                  <InboxIcon />
                </Badge>
              </ListItemIcon>
              <ListItemText
                primary={
                  <Typography
                    variant="overline"
                    className={classes.notificationHeaderText}
                  >
                    <span>Inbox</span>
                  </Typography>
                }
              />
            </ListItem>
            <ListItem button component={Link} to="/sessions/upcoming">
              <ListItemIcon>
                <SessionsIcon />
              </ListItemIcon>
              <ListItemText
                primary={
                  <Typography
                    variant="overline"
                    className={classes.notificationHeaderText}
                  >
                    <span>Sessions</span>
                  </Typography>
                }
              />
            </ListItem>
            <ListItem button component={Link} to="/tutor-tools">
              <ListItemIcon>
                <Badge
                  invisible={!showMessageBadge}
                  color="secondary"
                  badgeContent={messageBadgeAmount}
                >
                  <BuildRoundedIcon />
                </Badge>
              </ListItemIcon>
              <ListItemText
                primary={
                  <Typography
                    variant="overline"
                    className={classes.notificationHeaderText}
                  >
                    <span>Find Clients</span>
                  </Typography>
                }
              />
            </ListItem>
          </div>
        </List>
        <Divider />
        {
          <List onClick={handleDrawerToggle}>
            <div>
              <ListItem button component={Link} to="/tutor-refer-client">
                <ListItemIcon>
                  <Referral />
                </ListItemIcon>
                <ListItemText
                  primary={
                    <Typography
                      variant="overline"
                      className={classes.notificationHeaderText}
                    >
                      <span>Refer Clients</span>
                    </Typography>
                  }
                  secondary={
                    <Typography variant="body2" className={classes.secondary}>
                      Clients you refer get $20.
                    </Typography>
                  }
                />
              </ListItem>
              <ListItem button component={Link} to="/tutor-refer-tutor">
                <ListItemIcon>
                  <Referral />
                </ListItemIcon>
                <ListItemText
                  primary={
                    <Typography
                      variant="overline"
                      className={classes.notificationHeaderText}
                    >
                      <span>Refer Tutors</span>
                    </Typography>
                  }
                />
              </ListItem>
            </div>
          </List>
        }
        <Divider />
        <List onClick={handleDrawerToggle}>
          <div>
            <ListItem button component={Link} to="/account-settings">
              <ListItemIcon>
                <AccountSettings />
              </ListItemIcon>
              <ListItemText
                primary={
                  <Typography
                    variant="overline"
                    className={classes.notificationHeaderText}
                  >
                    <span>Account Settings</span>
                  </Typography>
                }
              />
            </ListItem>
            <ListItem button component={Link} to="/search">
              <ListItemIcon>
                <SearchIcon />
              </ListItemIcon>
              <ListItemText
                primary={
                  <Typography
                    variant="overline"
                    className={classes.notificationHeaderText}
                  >
                    <span>Search Tutors</span>
                  </Typography>
                }
              />
            </ListItem>
          </div>
        </List>
        <Divider />
        <Divider />
        <List onClick={handleDrawerToggle}>
          <div>
            <ListItem button component={Link} to="/" onClick={logout}>
              <ListItemIcon>
                <ExitToAppIcon />
              </ListItemIcon>
              <ListItemText
                primary={
                  <Typography
                    variant="overline"
                    className={classes.notificationHeaderText}
                  >
                    <span>Logout</span>
                  </Typography>
                }
              />
            </ListItem>
          </div>
        </List>
      </div>
    )}
    {!isTutorContext && (
      <div>
        <List onClick={handleDrawerToggle}>
          <div>
            <ListItem disableRipple onClick={editProfile}>
              <ListItemText
                primary={
                  <Fragment>
                    <Grid
                      container
                      direction="row"
                      justify="flex-start"
                      alignItems="center"
                      wrap="nowrap"
                    >
                      <Grid item>
                        <ProfileAvatar
                          firstName={
                            currentUser ? currentUser.profile.firstName : ''
                          }
                          lastName={
                            currentUser ? currentUser.profile.lastName : ''
                          }
                          src={
                            currentUser
                              ? currentUser.profile.profilePicture.src
                              : ''
                          }
                          size={AVATAR_STYLE_XS}
                        />
                      </Grid>
                      <Grid
                        className={classes.profileDescriptionContainer}
                        item
                        xs
                        container
                        direction="column"
                        justify="center"
                        alignItems="flex-start"
                        wrap="nowrap"
                      >
                        <Typography
                          className={classes.nameLabel}
                          variant="subtitle1"
                        >{`${currentUser.profile.firstName} ${
                          currentUser.profile.lastName
                        }`}</Typography>
                        <Typography
                          variant="body2"
                          className={classes.profileDescriptionLabel}
                        >
                          {`${profileLabel} on Tutorfly`}
                        </Typography>
                      </Grid>
                    </Grid>
                    <div className={classes.viewProfileContainer}>
                      {isDualProfile && (
                        <Grid
                          container
                          direction="row"
                          justify="center"
                          alignItems="center"
                        >
                          <Chip
                            icon={<SwapCallsIcon />}
                            label={`Switch to ${
                              isTutorContext ? 'client' : 'tutor'
                            } mode`}
                            onClick={handleContextClick}
                            variant="outlined"
                            className={classes.switchChipStyle}
                          />
                        </Grid>
                      )}
                    </div>
                  </Fragment>
                }
              />
            </ListItem>
          </div>
        </List>
        <Divider />
        <List onClick={handleDrawerToggle}>
          <div>
            <ListItem button component={Link} to="/sessions/upcoming">
              <ListItemIcon>
                <SessionsIcon />
              </ListItemIcon>
              <ListItemText
                primary={
                  <Typography
                    variant="overline"
                    className={classes.notificationHeaderText}
                  >
                    <span>Sessions</span>
                  </Typography>
                }
              />
            </ListItem>
            <ListItem button component={Link} to="/inbox">
              <ListItemIcon>
                <Badge
                  invisible={!showMessageBadge}
                  color="secondary"
                  badgeContent={messageBadgeAmount}
                >
                  <InboxIcon />
                </Badge>
              </ListItemIcon>
              <ListItemText
                primary={
                  <Typography
                    variant="overline"
                    className={classes.notificationHeaderText}
                  >
                    <span>Inbox</span>
                  </Typography>
                }
              />
            </ListItem>
            {SHOW_REQUEST_A_TUTOR_FORM && (
              <ListItem button component={Link} to="/request-a-tutor">
                <ListItemIcon>
                  <Request />
                </ListItemIcon>
                <ListItemText
                  primary={
                    <Typography
                      variant="overline"
                      className={classes.notificationHeaderText}
                    >
                      <span>Request a Tutor</span>
                    </Typography>
                  }
                />
              </ListItem>
            )}
            <ListItem button component={Link} to="/search">
              <ListItemIcon>
                <SearchIcon />
              </ListItemIcon>
              <ListItemText
                primary={
                  <Typography
                    variant="overline"
                    className={classes.notificationHeaderText}
                  >
                    <span>Search Tutors</span>
                  </Typography>
                }
              />
            </ListItem>
          </div>
        </List>
        <Divider />
        {
          <List onClick={handleDrawerToggle}>
            <div>
              <ListItem button component={Link} to="/refer-friend">
                <ListItemIcon>
                  <Referral />
                </ListItemIcon>
                <ListItemText
                  primary={
                    <Typography
                      variant="overline"
                      className={classes.notificationHeaderText}
                    >
                      <span>Refer Friends</span>
                    </Typography>
                  }
                  secondary={
                    <Typography variant="body2" className={classes.secondary}>
                      Get $20 for each client you refer.
                    </Typography>
                  }
                />
              </ListItem>
              {isStudentProfile && (
                <ListItem button component={Link} to="/student-become-tutor">
                  <ListItemIcon>
                    <CastForEducationIcon />
                  </ListItemIcon>
                  <ListItemText
                    primary={
                      <Typography
                        variant="overline"
                        className={classes.notificationHeaderText}
                      >
                        <span>Become a Tutor</span>
                      </Typography>
                    }
                    secondary={
                      <Typography variant="body2" className={classes.secondary}>
                        Earn $25+ per hour
                      </Typography>
                    }
                  />
                </ListItem>
              )}
            </div>
          </List>
        }
        <Divider />
        <List onClick={handleDrawerToggle}>
          <div>
            <ListItem button component={Link} to="/account-settings">
              <ListItemIcon>
                <AccountSettings />
              </ListItemIcon>
              <ListItemText
                primary={
                  <Typography
                    variant="overline"
                    className={classes.notificationHeaderText}
                  >
                    <span>Account Settings</span>
                  </Typography>
                }
              />
            </ListItem>
          </div>
        </List>
        <Divider />
        <List onClick={handleDrawerToggle}>
          <div>
            <ListItem button component={Link} to="/" onClick={logout}>
              <ListItemIcon>
                <ExitToAppIcon />
              </ListItemIcon>
              <ListItemText
                primary={
                  <Typography
                    variant="overline"
                    className={classes.notificationHeaderText}
                  >
                    <span>Logout</span>
                  </Typography>
                }
              />
            </ListItem>
          </div>
        </List>
      </div>
    )}
    {isAdmin && (
      <List onClick={handleDrawerToggle}>
        <div>
          <Divider />
          <ListItem button component={Link} to="/admin">
            <ListItemIcon className={classes.muiListIcon}>
              <LensRoundedIcon />
            </ListItemIcon>
            <ListItemText
              className={classes.profileListItemText}
              primary={
                <Typography
                  variant="overline"
                  className={classes.notificationHeaderText}
                >
                  <span>Admin Panel</span>
                </Typography>
              }
            />
          </ListItem>
          <Divider />
        </div>
      </List>
    )}
  </div>
);
