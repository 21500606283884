import React from 'react';
import Avatar from '@material-ui/core/Avatar';
import PropTypes from 'prop-types';
import { hiddenProfileGrey } from 'shared/constants/styleConstants';
import CircularProgress from '@material-ui/core/CircularProgress';
import Grid from '@material-ui/core/Grid';
import {
  AVATAR_STYLE_XS,
  AVATAR_STYLE_SM,
  AVATAR_STYLE_MD,
  AVATAR_STYLE_LG,
  AVATAR_STYLE_VARIABLE_V2,
  AVATAR_STYLE_VARIABLE,
  AVATAR_STYLE_MESSAGE_PROFILE_VARIABLE,
  AVATAR_STYLE_REFERRAL,
  AVATAR_STYLE_EDIT_PROFILE,
  AVATAR_STYLE_MOBILE_MESSENGER,
  AVATAR_SESSION_CARD_STYLE_VARIABLE,
  AVATAR_SESSION_CARD_STYLE_V2,
  AVATAR_STYLE_REQUEST,
  AVATAR_STYLE_HEADER_BAR,
} from 'shared/constants/index';
import {
  mapNameToColor,
  getInitials,
  getInitial,
} from 'shared/utilities/initials';
import withStyles from './ProfileAvatarStyles';

/**
 * Wrapper component for the material UI avatar. If there isn't a profile picture for a user
 * then we generate a background color and use the user's initials as the avatar.
 */
class ProfileAvatar extends React.Component {
  static propTypes = {
    classes: PropTypes.object.isRequired, // eslint-disable-line
    firstName: PropTypes.string.isRequired,
    lastName: PropTypes.string.isRequired,
    src: PropTypes.string,
    alt: PropTypes.string,
    size: PropTypes.string,
    centered: PropTypes.bool,
    transform: PropTypes.object, // eslint-disable-line
    onClickFunction: PropTypes.func,
    isSquare: PropTypes.bool,
    lockedProfile: PropTypes.bool,
    isLoading: PropTypes.bool,
  };

  static defaultProps = {
    src: '',
    alt: '',
    size: '',
    centered: false,
    transform: {},
    onClickFunction: () => {},
    isSquare: false,
    lockedProfile: false,
    isLoading: false,
  };

  /* render user.jpg if a src is passed in but there's some error with the image */
  setDefaultImage = e => {
    e.target.src = '/images/user.jpg';
  };

  /* return the withStyles class based on the size prop */
  getSizeClass = () => {
    const { size, classes } = this.props;

    if (size === AVATAR_SESSION_CARD_STYLE_VARIABLE)
      return classes.sessionCardVariableAvatar;
    if (size === AVATAR_SESSION_CARD_STYLE_V2)
      return classes.sessionCardVariableV2;
    if (size === AVATAR_STYLE_VARIABLE) return classes.variableAvatar;
    if (size === AVATAR_STYLE_MESSAGE_PROFILE_VARIABLE)
      return classes.variableMessageProfileAvatar;
    if (size === AVATAR_STYLE_MOBILE_MESSENGER)
      return classes.messageMobileHeaderAvatar;
    if (size === AVATAR_STYLE_REQUEST) return classes.tutorRequestAvatar;
    if (size === AVATAR_STYLE_XS) return classes.extraSmallAvatar;
    if (size === AVATAR_STYLE_SM) return classes.smallAvatar;
    if (size === AVATAR_STYLE_MD) return classes.mediumAvatar;
    if (size === AVATAR_STYLE_LG) return classes.largeAvatar;
    if (size === AVATAR_STYLE_VARIABLE_V2) return classes.variableAvatarV2;
    if (size === AVATAR_STYLE_REFERRAL) return classes.referralAvatar;
    if (size === AVATAR_STYLE_HEADER_BAR) return classes.headerBarAvatar;
    if (size === AVATAR_STYLE_EDIT_PROFILE) return classes.editProfileAvatar;

    return classes.avatar; // default if prop isn't passed
  };

  /* return the withStyles class based on the centered prop */
  getCenteredClass = () => {
    const { centered, classes } = this.props;

    if (centered) {
      return classes.centered;
    }
    return 'false'; // default if prop isn't passed
  };

  render() {
    const {
      isSquare,
      src,
      classes,
      alt,
      transform,
      firstName,
      lastName,
      onClickFunction,
      lockedProfile,
      isLoading,
    } = this.props;

    if (isLoading) {
      return (
        <div className={this.getSizeClass()}>
          <Grid fullWidth container justify="center" alignItems="center">
            <CircularProgress />
          </Grid>
        </div>
      );
    }

    if (isSquare) {
      return src && src !== '/images/user.jpg' ? (
        <div className={classes.squareAvatarWrapper}>
          <img
            className={classes.squareAvatarImage}
            src={src}
            alt={alt}
            onError={this.setDefaultImage}
            style={transform}
          />
        </div>
      ) : (
        <div
          className={classes.squareAvatarWrapper}
          style={{ background: mapNameToColor(firstName, lastName) }}
        >
          <p className={classes.squareAvatarInitials}>
            {getInitials(firstName, lastName)}
          </p>
        </div>
      );
    }

    if (lockedProfile) {
      return (
        <Avatar
          className={[this.getSizeClass(), this.getCenteredClass()]}
          style={{ background: hiddenProfileGrey }}
        >
          {getInitial(firstName)}
        </Avatar>
      );
    }

    return src && src !== '/images/user.jpg' ? (
      <Avatar
        className={[this.getSizeClass(), this.getCenteredClass()]}
        style={{
          marginTop: '0px',
        }}
        src={src}
        alt={alt}
        onError={this.setDefaultImage}
        onClick={onClickFunction}
      />
    ) : (
      <Avatar
        className={[this.getSizeClass(), this.getCenteredClass()]}
        style={{
          background: mapNameToColor(firstName, lastName),
          marginTop: '0px',
        }}
      >
        {getInitials(firstName, lastName)}
      </Avatar>
    );
  }
}

export default withStyles(ProfileAvatar);
