import React from 'react';
import PropTypes from 'prop-types';
import { compose } from 'redux';
import moment from 'moment-timezone';

import Box from '@material-ui/core/Box';
import CircularProgress from '@material-ui/core/CircularProgress';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import IconButton from '@material-ui/core/IconButton';
import Slide from '@material-ui/core/Slide';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { useTheme, makeStyles } from '@material-ui/core/styles';
import CloseIcon from '@material-ui/icons/Close';

import PrimaryButtonV3 from 'shared/components/Buttons/PrimaryButtonV3';
import withClient from 'shared/components/ApolloClient/withClient';
import { FIX_ERROR_SESSION_MUTATION } from 'shared/constants/gql-constants';
import { errorAlert } from 'shared/utilities/alerts';
import { getErrorMessage, getUsersForSession } from 'shared/utilities/utils';

const Transition = React.forwardRef((props, ref) => (
  <Slide direction="up" ref={ref} {...props} />
));

const dialogStyles = makeStyles(theme => ({
  container: {
    marginBottom: theme.spacing(2),
  },
}));

const rowStyles = makeStyles(() => ({
  payButton: {
    whiteSpace: 'nowrap',
  },
}));

const getFormattedDateTime = (
  sessionDate,
  sessionStartTime,
  sessionEndTime,
  sessionTimeZoneId,
  userTimeZoneId,
) => {
  const startTimeMoment = moment
    .tz(`${sessionDate} ${sessionStartTime}`, sessionTimeZoneId)
    .tz(userTimeZoneId);
  const formattedDate = startTimeMoment.format('dddd, MMMM Do');
  const formattedStartTime = startTimeMoment.format('LT');
  const formattedEndTime = moment
    .tz(`${sessionDate} ${sessionEndTime}`, null)
    .tz(userTimeZoneId)
    .format('LT z');
  const formattedTime = `${formattedStartTime} - ${formattedEndTime}`;

  return {
    formattedDate,
    formattedTime,
  };
};

function PaymentErrorRow({ client, session, currentUser, removeSession }) {
  const classes = rowStyles();
  const [fixSessionIsLoading, setFixSessionIsLoading] = React.useState(false);
  const { id, startTime, endTime, date } = session;
  const participants = getUsersForSession(session);
  const tutorProfile = participants.tutors[0].user.profile;
  const selfParticipant = participants.tutees.filter(
    tutee => tutee.user.id === currentUser.id,
  )[0];
  const { formattedDate, formattedTime } = getFormattedDateTime(
    date,
    startTime,
    endTime,
    session.timeZoneId,
    currentUser.profile.timeZoneId,
  );

  const fixPaymentError = async sessionId => {
    setFixSessionIsLoading(true);
    try {
      await client.mutate({
        mutation: FIX_ERROR_SESSION_MUTATION,
        variables: {
          sessionId,
        },
      });
      removeSession(sessionId);
      setFixSessionIsLoading(false);
    } catch (err) {
      errorAlert(
        'Session Processing Error',
        `There was an issue fixing the session: ${getErrorMessage(err)}`,
      );
      setFixSessionIsLoading(false);
    }
  };

  let roundedClientSessionPayment;
  if (typeof selfParticipant.finalClientPaymentAmount === 'number') {
    const clientTotalChargeValue = selfParticipant.finalClientPaymentAmount;
    roundedClientSessionPayment = clientTotalChargeValue;
  } else {
    const totalClientSessionPayment =
      selfParticipant.tutorBasePaymentAmount * 1.077;
    roundedClientSessionPayment = totalClientSessionPayment.toFixed(2);
  }

  return (
    <TableRow key={id}>
      <TableCell display="block">{`${tutorProfile.firstName} ${
        tutorProfile.lastName
      }`}</TableCell>
      <TableCell display="block">{formattedDate}</TableCell>
      <TableCell display="block">{formattedTime}</TableCell>
      <TableCell display="block">{`$${roundedClientSessionPayment}`}</TableCell>
      <TableCell display="block">{`$${selfParticipant.remaining}`}</TableCell>
      <TableCell display="block">
        {!fixSessionIsLoading && (
          <PrimaryButtonV3
            variant="contained"
            onClick={() => fixPaymentError(id)}
            className={classes.payButton}
          >
            Pay Now
          </PrimaryButtonV3>
        )}
        {fixSessionIsLoading && <CircularProgress size={20} />}
      </TableCell>
    </TableRow>
  );
}

PaymentErrorRow.propTypes = {
  client: PropTypes.shape.isRequired,
  session: PropTypes.shape.isRequired,
  currentUser: PropTypes.shape.isRequired,
  removeSession: PropTypes.func.isRequired,
};

function SessionErrorDialog({
  client,
  open,
  onClose,
  sessions,
  currentUser,
  removeSession,
}) {
  const classes = dialogStyles();
  const theme = useTheme();
  const isFullScreen = useMediaQuery(theme.breakpoints.down('sm'));

  return (
    <Dialog
      maxWidth="md"
      TransitionComponent={Transition}
      scroll="body"
      fullScreen={isFullScreen}
      fullWidth
      onClose={onClose}
      open={open}
      aria-labelledby="Fix Session Payments"
    >
      <DialogTitle>
        <Box display="flex" alignItems="center">
          <Box flexGrow={1}>Fix Session Errors</Box>
          <Box>
            <IconButton aria-label="close" onClick={onClose}>
              <CloseIcon />
            </IconButton>
          </Box>
        </Box>
      </DialogTitle>
      <DialogContent>
        <TableContainer className={classes.container}>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>Tutor</TableCell>
                <TableCell>Date</TableCell>
                <TableCell>Time</TableCell>
                <TableCell>Session Total</TableCell>
                <TableCell>Remaining Payment</TableCell>
                <TableCell />
              </TableRow>
            </TableHead>
            <TableBody>
              {sessions.map(session => (
                <PaymentErrorRow
                  session={session}
                  client={client}
                  currentUser={currentUser}
                  removeSession={removeSession}
                />
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </DialogContent>
    </Dialog>
  );
}

SessionErrorDialog.propTypes = {
  client: PropTypes.shape.isRequired,
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  sessions: PropTypes.arrayOf(PropTypes.shape.isRequired).isRequired,
  currentUser: PropTypes.shape.isRequired,
  removeSession: PropTypes.func.isRequired,
};

export default compose(withClient)(SessionErrorDialog);
