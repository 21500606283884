import history from '../../history';

export const navigateHome = () =>
  history.push('/', { prevURL: window.location.href });

export const navigateLogin = () =>
  history.push('/login', { prevURL: window.location.href });

export const navigateSignup = () =>
  history.push('/signup', { prevURL: window.location.href });

export const navigateSearch = () =>
  history.push('/search', { prevURL: window.location.href });

export const navigateFindATutor = () =>
  history.push('/find-a-tutor', { prevURL: window.location.href });

export const navigateFindTutorByName = () => {
  history.push('/find-a-tutor/name', { prevURL: window.location.href });
};

export const navigateResetPassword = () =>
  history.push('/forgot-password', { prevURL: window.location.href });

export const navigateUpcomingSessions = () =>
  history.push('/sessions/upcoming', { prevURL: window.location.href });

export const navigateWelcomePersonalized = () =>
  history.push('/w/h', { prevURL: window.location.href });

export const navigateToWelcomePage = () =>
  history.push('/welcome#e_mail', { prevURL: window.location.href });

export const navigateToTutorWelcomePage = () =>
  history.push('/account-settings', { prevURL: window.location.href });

export const navigateToProfilePage = profileId => {
  history.push(`/u/${profileId}`, { prevURL: window.location.href });
};

export const navigateToTutorConnectDialog = profileId =>
  history.push(`/u/${profileId}?connectForm=open`, {
    prevURL: window.location.href,
  });

export const navigateBecomeATutor = () => {
  history.push(`/student-become-tutor`, { prevURL: window.location.href });
};

export const navigateEditProfile = () =>
  history.push('/edit-profile', {
    prevURL: window.location.href,
  });

export const navigateAccountSettings = () =>
  history.push('/account-settings', {
    prevURL: window.location.href,
  });

export const navigateRequestATutor = () =>
  history.push('/request-a-tutor', { prevURL: window.location.href });

export const navigateSessionTutoringRequests = () =>
  history.push('/session-tutoring-requests', { prevURL: window.location.href });

export const navigateClientReferral = () => {
  history.push('/refer-friend', {
    prevURL: window.location.href,
  });
};

export const navigateTutorReferral = () => {
  history.push('/tutor-refer-tutor', {
    prevURL: window.location.href,
  });
};

export const navigateTutorReferralForClient = () => {
  history.push('/tutor-refer-client', {
    prevURL: window.location.href,
  });
};

export const navigateToConversation = conversationId => {
  history.push(`/conversation/${conversationId}`, {
    prevURL: window.location.href,
  });
};

export const navigateToConversationDetailsTab = conversationId => {
  history.push(`/conversation/${conversationId}?tab=2`, {
    prevURL: window.location.href,
  });
};

export const navigateToPaymentMethod = () => {
  history.push(`/account-settings#payment_method`, {
    prevURL: window.location.href,
  });
};

export const navigateToSessions = () =>
  history.push('/sessions/upcoming', {
    prevURL: window.location.href,
  });

export const navigateToInbox = () =>
  history.push('/inbox', { prevURL: window.location.href });

export const navigateToPathName = path =>
  history.push(path, { prevURL: window.location.href });

export const navigateAdminPanel = () =>
  history.push('/admin', { prevURL: window.location.href });

export const goBack = () => history.goBack();
