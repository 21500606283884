import React from 'react';
import PropTypes from 'prop-types';
import withStyles from './FeatureLinkStyles';
import PrimaryButtonV4 from '../../../shared/components/Buttons/PrimaryButtonV4';

const FeatureLink = ({
  title,
  description,
  icon,
  classes,
  link,
  linkText,
  color,
}) => {
  return (
    <div className={classes.container} style={{ color }}>
      <div className={classes.iconContainer}>
        <img src={icon} alt={title} className={classes.icon} />
      </div>
      <h2 style={{ margin: '1em auto', textAlign: 'center' }}>{title}</h2>
      <div className={classes.description}>{description}</div>
      {link ? (
        <PrimaryButtonV4
          color="orange"
          style={{ display: 'block', margin: 'auto auto 0 auto' }}
          href={link}
        >
          {linkText || 'Learn More'}
        </PrimaryButtonV4>
      ) : null}
    </div>
  );
};

FeatureLink.propTypes = {
  title: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
  link: PropTypes.string,
  linkText: PropTypes.string,
  icon: PropTypes.string.isRequired,
  classes: PropTypes.object.isRequired, // eslint-disable-line
  color: PropTypes.oneOf(['black', 'white']),
};

FeatureLink.defaultProps = {
  color: 'black',
  link: '',
  linkText: '',
};

export default withStyles(FeatureLink);
