import React from 'react';
import PropTypes from 'prop-types';
import Grid from '@material-ui/core/Grid';
import TopWave from 'shared/assets/svg/wave-top-inverse.svg';
import BottomWave from 'shared/assets/svg/rounded-wave-bottom.svg';
import BottomWaveMobile from 'shared/assets/svg/landing-wave-bottom.svg';
import Teacher from 'shared/assets/images/teacher-testimonial-mrs-rouse.png';
import withStyles from './TestimonialHeroStyles';

const TestimonialHero = ({ classes }) => (
  <div className={classes.heroContent}>
    <Grid item className={classes.topWave}>
      <img src={TopWave} width="100%" alt="" />
    </Grid>
    <div className={classes.innerContent}>
      <Grid item>
        <img className={classes.image} src={Teacher} alt="A Tutorfly Student" />
      </Grid>
      <Grid item className={classes.textContainer}>
        <p>
          &quot;My experience with Tutorfly has been amazing from the beginning.
          I think that the partnership between Butler and Tutorfly is
          invaluable.
        </p>
        <p>
          We have made a huge difference in these kids&apos; lives (and)
          we&apos;re working toward that achievement gap. I know it has made a
          big difference in helping our teachers by allowing us to have smaller
          groups.&quot;
        </p>
      </Grid>
      <Grid item className={classes.captionContainer}>
        <p>
          <b style={{ fontWeight: 'bold' }}>Mrs Rouse, Teacher</b>
        </p>
        <p>Alta E. Butler Elementary School, Phoenix, Arizona</p>
      </Grid>
    </div>
    <Grid item className={classes.bottomWave}>
      <img
        src={BottomWave}
        width="100%"
        alt=""
        className={classes.bottomWaveDesktop}
      />
      <img
        src={BottomWaveMobile}
        width="100%"
        alt=""
        className={classes.bottomWaveMobile}
      />
    </Grid>
  </div>
);

TestimonialHero.propTypes = {
  classes: PropTypes.object.isRequired, // eslint-disable-line
};

export default withStyles(TestimonialHero);
