import React from 'react';
import PropTypes from 'prop-types';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import withStyles from './LandingTextStyles';

const LandingText = ({ classes }) => (
  <Grid
    container
    justify="space-evenly"
    alignItems={{
      xs: 'start',
      lg: 'center',
    }}
    direction="column"
    className={classes.container}
    item
    xs={12}
  >
    <Grid item>
      <Typography variant="h2" className={classes.title}>
        High-Impact Tutoring for K-12 students delivering proven results!
      </Typography>
    </Grid>
  </Grid>
);

LandingText.propTypes = {
  classes: PropTypes.object.isRequired, // eslint-disable-line
};

export default withStyles(LandingText);
