import withStyles from '@material-ui/core/styles/withStyles';
import * as StyleConstants from 'shared/constants/styleConstants';

const styles = theme => ({
  container: {
    height: '90%',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'start',
    alignItems: 'start',
    padding: theme.spacing(3),
  },
  iconContainer: {
    backgroundColor: StyleConstants.primaryColorDark,
    borderRadius: '100%',
    margin: '1em auto',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    height: '150px',
    width: '150px',
    [theme.breakpoints.only('md')]: {
      height: '125px',
      width: '125px',
    },
  },
  icon: {
    margin: 'auto',
    height: '55%',
    width: '55%',
  },
  description: {
    textAlign: 'center',
    display: 'none',
    width: '100%',
    whiteSpace: 'pre-line',
    marginBottom: '1.5em',
    [theme.breakpoints.up('sm')]: {
      display: 'block',
    },
  },
});

export default withStyles(styles);
